import React, { FunctionComponent } from 'react';

const ArticlesBenefitIcon: FunctionComponent = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6134_3467)">
      <path d="M1.5 19.5L8.5 37H37L19 5L1.5 19.5Z" fill="#F85B2E" />
      <rect x="14" y="15.3628" width="16" height="3" transform="rotate(-35.8163 14 15.3628)" fill="#25D3FF" />
      <rect x="14" y="23.3628" width="16" height="3" transform="rotate(-35.8163 14 23.3628)" fill="#25D3FF" />
      <rect x="21" y="26.3628" width="20.6938" height="3" transform="rotate(-35.8163 21 26.3628)" fill="#25D3FF" />
    </g>
    <defs>
      <clipPath id="clip0_6134_3467">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArticlesBenefitIcon;
