import React from 'react';
import { SubscriptionPlan } from '~/resources/models';
import SubscriptionFormItem from '~/components/PaidSubscription/ui/SubscriptionFormItem';

import Paragraph from '~/components/typography/Paragraph';
import c from './SubscriptionFormNew.sass';

type TProps = {
  plans: SubscriptionPlan[];
  onSubmit: (plan: SubscriptionPlan) => void;
  itemClassName?: string;
}

export default function SubscriptionFormNew(props: TProps) {
  const { plans, onSubmit, itemClassName } = props;

  const handleSubmit = (plan: SubscriptionPlan) => {
    onSubmit(plan);
  };

  return (
    <div className={c.subscription_form}>
      <div className={c.items_container}>
        {plans.map((plan) => (
          <SubscriptionFormItem
            key={plan.id}
            plan={plan}
            onSubmit={() => handleSubmit(plan)}
            className={itemClassName}
          />
        ))}
      </div>
      <div className={c.caption_container}>
        <Paragraph weight="bold" className={c.patreon_caption}>
          Нет российской карты? Можно подписаться на
          {' '}
          <a
            href="https://www.patreon.com/remindermedia"
            target="_blank"
            rel="noreferrer"
          >
            Patreon
          </a>
        </Paragraph>
        <Paragraph variant="caption">
          <a href="/public_offer_subscription" target="_blank">
            Оферта
          </a>
          {' '}
          и
          {' '}
          <a href="/privacy_policy" target="_blank">
            политика конфиденциальности
          </a>
        </Paragraph>
      </div>
    </div>
  );
}
