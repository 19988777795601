import React, { useEffect, useState } from 'react';
import SubscriptionFormNew from '~/components/PaidSubscription/ui/SubscriptionFormNew';

import useSubscription from '~/resources/useSubscription';
import Loader from '~/components/Loader';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import { SubscriptionPlan } from '~/resources/models';
import {
  AuthModalFrom, AuthModalState, SubscriptionFlowStep, SubscriptionType, usePageContext,
} from '~/components';
import { useRouteMatch } from 'react-router-dom';
import { SubscriptionPayment } from '~/components/PaidSubscription/SubscriptionPayment';
import { SubscriptionCheck } from '~/components/PaidSubscription/SubscriptionCheck';
import { SubscriptionStatusModal } from '~/components/PaidSubscription/SubscriptionStatusModal';
import useProfile from '~/resources/useProfile';
import { SubscriptionStatus } from '~/utils/purshase-event';
import Button from '~/components/Button/Button';
import Paragraph from '~/components/typography/Paragraph';
import Title from '~/components/typography/Title';
import disableScroll from '~/utils/disableScroll';
import { FetchStatus } from '~/resources';
import c from './SubscriptionFormSection.sass';

export default function SubscriptionFormSection() {
  const { url } = useRouteMatch();
  const { fetchBy: fetchPlans, data: plans } = useSubscription();

  const {
    auth, profile, profileStatus, authModal,
  } = usePageContext();

  const {
    buySubscription,
    subscriptionFlowVal,
    cancelSubscription,
    checkPayment,
  } = useSubscriptionFlow();

  const { fetchBy: fetchProfile } = useProfile();
  const step = subscriptionFlowVal && subscriptionFlowVal.step;
  const plan = subscriptionFlowVal && subscriptionFlowVal.plan;
  const [finalStatus, setFinalStatus] = useState<SubscriptionStatus>();
  const [orderId, setOrderId] = useState<number>();

  const onLogin = () => {
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.CHECK, authModalFrom: AuthModalFrom.PAYWALL });
    }
    disableScroll(true);
  };

  const profileStatusValue = profileStatus?.[0] ?? undefined;
  const isLoading = !profileStatusValue || profileStatusValue === FetchStatus.Fetching;
  const isProfile = profile && profile[0];
  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;

  const buy = (selectedPlan: SubscriptionPlan) => {
    buySubscription(selectedPlan, SubscriptionType.NEW, url);
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const onSuccessPayment = (oId: number) => {
    setOrderId(oId);
    checkPayment();
  };

  const cancel = () => {
    setFinalStatus(undefined);
    cancelSubscription();
    fetchProfile();
    fetchPlans();
  };

  const onStatus = (status: SubscriptionStatus) => {
    if (status === SubscriptionStatus.PAYMENT_CHECK_PROCESSED) {
      fetchProfile();
      auth?.clearCache();
    }
    setFinalStatus(status);
    cancelSubscription();
  };

  const renderPayment = () => (step === SubscriptionFlowStep.PAYMENT && plan
    ? (
      <SubscriptionPayment
        plan={plan}
        promocode=""
        onError={onStatus}
        onSuccess={onSuccessPayment}
        onClose={cancel}
      />
    )
    : null
  );

  const renderCheck = () => (step === SubscriptionFlowStep.CHECK && orderId && (
    <SubscriptionCheck
      orderId={orderId}
      onClose={cancel}
      onCheck={onStatus}
    />
  ));

  const renderStatus = () => (finalStatus && (
    <SubscriptionStatusModal status={finalStatus} onClose={cancel} />
  ));

  if (isSubscribed) return null;

  if (!plans) return (<Loader />);

  const plansSorted = plans.sort((a, b) => b.id - a.id);

  return (
    <div className={c.subscription_form_section}>
      {renderPayment()}
      {renderCheck()}
      {renderStatus()}
      <Title level={3}>
        Выбирайте удобный вид оплаты и получите доступ ко всем гайдам, статьям и сообществу Reminder
      </Title>
      {!isLoading && !isProfile && (
        <Button variant="secondary" size="large" className={c.subscription_form_section__btn_has_subscription} onClick={onLogin}>
          <Paragraph weight="bold">У меня есть подписка</Paragraph>
        </Button>
      )}
      <SubscriptionFormNew plans={plansSorted} onSubmit={buy} />
    </div>
  );
}
