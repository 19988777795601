import React, { useEffect, useRef, useState } from 'react';
import { FetchStatus, useConfig } from '~/resources';
import { usePageContext } from '~/components';
import classes from './Banner.sass';

// ms for 1px
const SPEED = 16;

// ms before animation start
const DELAY = 2000;

const Banner: React.FC = () => {
  const { status, fetchBy, data: config } = useConfig();
  const banner = config?.banner;
  const rootRef = useRef<HTMLAnchorElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [isOverflow, setIsOverflow] = useState(false);
  const [showDublicate, setShowDublicate] = useState(false);
  const [show, setShow] = useState(false);
  const { profile, profileStatus } = usePageContext();

  useEffect(() => {
    fetchBy();
  }, []);

  useEffect(() => {
    if (profile && profileStatus && profileStatus[0] === FetchStatus.Fetched) {
      if (profile[0] && profile[0].is_subscribed) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  }, [profile, profileStatus]);

  const animate = () => {
    if (rootRef.current && contentRef.current) {
      const { scrollWidth } = contentRef.current;
      if (isOverflow) {
        if (rootRef.current.scrollLeft < scrollWidth) {
          rootRef.current.scrollTo(rootRef.current?.scrollLeft + 1, 0);
        } else {
          setIsOverflow(false);
        }
      }
    }
  };

  useEffect(() => {
    if (isOverflow) {
      timeoutRef.current = setTimeout(() => {
        intervalRef.current = setInterval(animate, SPEED);
      }, DELAY);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isOverflow]);

  const checkOverflow = () => {
    if (contentRef.current && window) {
      const windowWidth = window.innerWidth;
      const { scrollWidth } = contentRef.current;
      if (windowWidth < scrollWidth) {
        setIsOverflow(true);
        setShowDublicate(true);
      } else {
        setIsOverflow(false);
        setShowDublicate(false);
      }
    }
  };

  useEffect(() => {
    checkOverflow();
    if (window) {
      window.addEventListener('resize', checkOverflow);
    }
    return () => {
      if (window) {
        window.removeEventListener('resize', checkOverflow);
      }
    };
  }, [banner]);

  if (status !== FetchStatus.Fetched || !banner) {
    return null;
  }

  const gradient = () => banner.gradient.colors.map(
    (colorItem) => `${colorItem.color} ${colorItem.offset * 100}%`,
  );

  return (
    <>
      {show && (
        <a
          ref={rootRef}
          target="_blank"
          rel="noreferrer"
          href={banner.link}
          className={classes.wrapper}
        >
          <div
            className={classes.inner}
            style={{
              background: `linear-gradient(90deg, ${gradient().join(', ')})`,
            }}
          >
            <div className={classes.mainContent} ref={contentRef}>
              <span>
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.55078 5.42145L0.579988 10.6007L0.579988 0.242157L9.55078 5.42145Z" fill="white" />
                </svg>
              </span>
              <span className={classes.title}>
                {banner.title}
              </span>
              <span className={classes.subtitle}>{banner.subtitle}</span>
            </div>
            {
                showDublicate && (
                  <div className={classes.dublicateContent}>
                    <span>
                      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.55078 5.42145L0.579988 10.6007L0.579988 0.242157L9.55078 5.42145Z" fill="white" />
                      </svg>
                    </span>
                    <span className={classes.title}>
                      {banner.title}
                    </span>
                    <span className={classes.subtitle}>{banner.subtitle}</span>
                  </div>
                )
              }
          </div>
        </a>
      )}
    </>
  );
};

export default Banner;
