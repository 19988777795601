import React, { PropsWithChildren } from 'react';

import Title from '~/components/typography/Title';
import Paragraph from '~/components/typography/Paragraph';
import clsx from 'clsx';
import { TSubscriptionFeature } from '~/components/PaidSubscription/data/subscriptionFeaturesList';
import c from './SubscriptionFeatureItem.sass';

type TProps = PropsWithChildren<{
  feature: TSubscriptionFeature;
  withSeparator?: boolean;
  className?: string;
}>;

export default function SubscriptionFeatureItem(props: TProps) {
  const {
    feature, withSeparator, className, children,
  } = props;

  const { title, subtitle, description } = feature;

  return (
    <div className={clsx(c.subscription_feature_item, className, {
      [c.subscription_feature_item__with_separator]: withSeparator,
    })}
    >
      <div className={c.first_column}>
        <Title level={3} className={c.title}>{title}</Title>
        <Paragraph weight="bold">{subtitle}</Paragraph>
      </div>
      <div className={c.second_column}>
        <Paragraph>{description}</Paragraph>
        {children}
      </div>
    </div>
  );
}
