import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import config from '~/config';
import Banner from '~/components/Banner';
import { Page } from '~/components';
import { SubscriptionComponent } from '~/components/PaidSubscription';
import Helmeter from './Helmeter';

const SubscriptionPublicScreen: FunctionComponent = () => {
  const { discountPromocode } = useParams<{ discountPromocode: string | undefined }>();
  const ogImage = `${config('APP_HOST')}/public/subscription/subscription-og.png`;
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  const canonicalLink = `${config('APP_HOST')}/subscription`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="Оптимизация жизни"
          metaTitle="Оптимизация жизни"
          metaDescription="Подписка на лучшее знание о здоровье и саморазвитии"
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
          img={ogImage}
          srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
        />
        <SubscriptionComponent discountPromocode={discountPromocode} />
      </Page>
    </>
  );
};

export default SubscriptionPublicScreen;
