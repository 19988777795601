import React, { ReactElement, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import disableScroll from '~/utils/disableScroll';
import Loader from '../Loader';
import c from './Auth.sass';

import { CloseIcon, BackIcon } from './Icons';
import CheckForm from './CheckForm';
import SigninForm from './SigninForm';
import SignupForm from './SignupForm';
import {
  AuthModalFrom,
  AuthModalState,
  usePageContext,
} from '../page';
import ChangePasswordForm from './ChangePasswordForm';

const AuthModal = (): ReactElement | null => {
  const [email, setEmail] = useState('');

  const { authModal } = usePageContext();
  const history = useHistory();
  if (!authModal) {
    return null;
  }
  const [state, setState] = authModal;
  if (state.authModalState === AuthModalState.NONE) {
    if (email) {
      setEmail('');
    }
    return null;
  }

  const onEmailChanged = (_email: string) => {
    setEmail(_email);
  };

  const onClose = () => {
    setEmail('');
    setState({ authModalState: AuthModalState.NONE });
    disableScroll(false);
    const currentPath = window.location.pathname;
    if (currentPath.includes('/verify') || currentPath.includes('/changepassword')) {
      history.push('/');
    }
  };

  const getContent = (modalState: AuthModalState) => {
    switch (modalState) {
      case AuthModalState.SIGNIN:
        return (
          <SigninForm _email={email} from={state.authModalFrom ?? AuthModalFrom.NONE} />
        );
      case AuthModalState.SIGNUP:
        return (
          <SignupForm _email={email} from={state.authModalFrom ?? AuthModalFrom.NONE} />
        );
      case AuthModalState.LOADING:
        return (
          <Loader />
        );
      case AuthModalState.CHANGE_PASSWORD:
        return (
          <ChangePasswordForm urlKey={state.urlKey || ''} />
        );
      case AuthModalState.CHECK:
      default:
        return (
          <CheckForm
            onEmailChanged={onEmailChanged}
            email={email}
            from={state.authModalFrom ?? AuthModalFrom.NONE}
          />
        );
    }
  };

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      <button
        type="button"
        onClick={onClose}
        className={`${c.icon} ${c.icon_close}`}
      >
        <CloseIcon />
      </button>
      {(state.authModalState !== AuthModalState.CHECK
        && state.authModalState !== AuthModalState.CHANGE_PASSWORD) && (
        <button
          type="button"
          onClick={() => setState({ authModalState: AuthModalState.CHECK })}
          className={`${c.icon} ${c.icon_back}`}
        >
          <BackIcon />
        </button>
      )}
      {getContent(state.authModalState)}
    </Modal>
  );
};

export default AuthModal;
