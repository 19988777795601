import React, { HTMLAttributes } from 'react';

import clsx from 'clsx';
import c from './Button.sass';

type TProps = HTMLAttributes<HTMLButtonElement> & {
  variant?: 'secondary' | 'primary';
  size?: 'medium' | 'large';
}

export default function Button(props: TProps) {
  const {
    className, variant, size = 'primary', ...rest
  } = props;

  return (
    <button
      type="button"
      {...rest}
      className={clsx(c.button, className, {
        [c.button__secondary]: variant === 'secondary',
        [c.button__large]: size === 'large',
      })}
    />
  );
}
