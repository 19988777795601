import React, { FunctionComponent } from 'react';

import c from './ItSummaLogo.sass';

const ItSummaLogo: FunctionComponent = () => (
  <a target="_blank" href="https://www.itsumma.ru/development" className={c.wrapper} rel="noreferrer">
    <span className={c.text}>Разработано в</span>
    <svg className={c.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179 66.4">
      <g id="Слой_2" data-name="Слой 2">
        <g id="Слой_1-2" data-name="Слой 1">
          <path
            className="cls-1"
            fill="#f6300f"
            d="M25.17,23.91V35.8l-6.68,2V36.62l1.83-.91V25.85l-7.11,1.81V37.79L8.37,36V23.91H1.74V40.57l7.62,3.62v15.3H0V66.4H16.4V57.29h8.31V66.4h6.74v-17H22l1-5.25,8.37-3.44V23.91ZM1.66,21.69l29.7,0-6.12-7.2,11.68-5L35,4.6l-11.68,5L22.77,0Z"
          />
          <path
            className="cls-2"
            fill="#fff"
            d="M50.25,55.06h4.68V38.27H50.25ZM71,38.27V48.41H82.57V51H71v4.09h16.2V44.31H75.72v-2H87.25V38.27Zm31.08,0V51H95.28V38.27H90.6V55.06h16.2V38.27Zm8,0V55.06h4.68V42.36h4.47v12.7H124V42.36h4.47v12.7h4.68V38.27Zm26.32,0V55.06h4.68V42.36h4.47v12.7h4.68V42.36h4.47v12.7h4.67V38.27Zm31,10.14h6.85V51h-6.85ZM162.8,38.27v4.09h11.53v2H162.8V55.06H179V38.27ZM58.28,35.22V55.06H68V51H63V42.36h4.88V38.27H63V35.22Zm-8,1.47h4.68v-3H50.25Z"
          />
        </g>
      </g>
    </svg>
  </a>
);

ItSummaLogo.displayName = 'ItSummaLogo';

export default ItSummaLogo;
