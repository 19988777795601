import React from 'react';

import StarOutlinedIcon from '~/components/icons/StarOutlined';
import Title from '~/components/typography/Title';
import HeaderCard from '~/components/guides/header/HeaderCard';
import Button from '~/components/Button/Button';
import Paragraph from '~/components/typography/Paragraph';
import { Link } from 'react-router-dom';
import { usePageContext } from '~/components';
import c from './GuidesHeader.sass';

import image1 from './images/image1.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';

const cards = [
  {
    id: 1,
    title: 'Опора на научные исследования',
    imageSrc: image1,
  },
  {
    id: 2,
    title: 'Action Plans: инструкции, что делать',
    imageSrc: image2,
  },
  {
    id: 3,
    title: 'Возможность задать вопрос редакции',
    imageSrc: image3,
  },
];

export default function GuidesHeader() {
  const { profile } = usePageContext();

  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;

  if (isSubscribed) return null;

  return (
    <div className={c.guides_header}>
      <StarOutlinedIcon />
      <Title level={1} className={c.guides_header__title}>Гайды Reminder</Title>
      <Title level={4} className={c.guides_header__title}>
        Подробные разборы самых важных тем для подписчиков Reminder.
        Читайте и внедряйте в жизнь.
      </Title>
      <div className={c.guides_header__cards_container}>
        {cards.map(({ id, title, imageSrc }) => (
          <HeaderCard title={title} imageSrc={imageSrc} key={id} />
        ))}
      </div>
      <Link to="/subscription" className={c.guides_header__button}>
        <Button size="large">
          <Paragraph weight="bold" className={c.button_label}>Купить подписку</Paragraph>
        </Button>
      </Link>
    </div>
  );
}
