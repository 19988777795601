import React, { FunctionComponent } from 'react';

const EyeOpenIcon: FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18C17.5228 18 22 14 22 12C22 10 17.5228 6 12 6C6.47715 6 2 9.5 2 12C2 14.5 6.47715 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
      fill="#F85B2E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1426 11.257L12 12L11.257 10.1426C11.4867 10.0506 11.7375 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 11.7375 10.0506 11.4867 10.1426 11.257Z"
      fill="#F85B2E"
    />
  </svg>

);

export default EyeOpenIcon;
