import { useRouteMatch } from 'react-router';
import { usePageContext } from '~/components';
import c from '~/components/page/PageMain.sass';
import Paragraph from '~/components/typography/Paragraph';
import React, { useMemo } from 'react';

const ADMIN_URLS = ['/post/:slug', '/super/:slug', '/longread/:slug'];

export default function AdminPage() {
  const match = useRouteMatch<{ slug?: string }>();
  const { path, params: { slug } } = match;

  const { profile } = usePageContext();

  const [lastPost] = usePageContext().lastPost;

  const isAdmin = profile && profile[0] && profile[0].is_admin;
  const isAdminUrl = ADMIN_URLS.indexOf(path) >= 0;

  const editUrl = useMemo<string | null>(() => {
    if (!isAdminUrl) {
      return null;
    }
    let prefix = '/admin/editor-post';
    if (path === '/longread/:slug') {
      prefix = '/admin/editor-longread';
    }
    if (lastPost?.partner_material) {
      prefix = '/admin/editor-partner';
    }
    return `${prefix}/${slug}`;
  }, [isAdminUrl]);

  if (!isAdmin) return null;

  return (
    <>
      <div>
        <a href="/admin" target="_blank" className={c.login}>
          <Paragraph weight="bold">Управление</Paragraph>
        </a>
      </div>
      {editUrl && (
        <div>
          <a
            href={editUrl}
            rel="noreferrer"
            target="_blank"
            className={c.login}
          >
            <Paragraph weight="bold">Редактировать</Paragraph>
          </a>
        </div>
      )}
    </>
  );
}
