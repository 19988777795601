import React, { FunctionComponent, useEffect, useRef } from 'react';

import { Post } from '~/resources/models';
import { ModalSubscription, usePageContext } from '~/components';
import PostLike from '~/components/PostLike';
import { useConfig } from '~/resources';
import useExtendedLinks from '~/components/post/extendedLink/useExtendedLinks';
import ExtendedLinkTooltip from '~/components/post/extendedLink/ExtendedLinkTooltip';
import clsx from 'clsx';
import useGuideContents from '~/components/post/hooks/useGuideContents/useGuideContents';
import StickyButton from '~/components/post/stickyTableOfContents/StickyButton';
import useStickyTableOfContents from '~/components/post/stickyTableOfContents/useStickyTableOfContents';
import StickyTableOfContents from '~/components/post/stickyTableOfContents/StickyTableOfContents';
import useWindowDimensions from '~/utils/useWindowDimensions';
import { SCREEN_MD } from '~/utils/breakpoints';
import { useScrolledGa, useViewedGa } from './ga.post';
import ServiceText from '../ServiceText';
import Paywall from '../Paywall';
import c from './ContentPost.sass';

const HOSTS = {
  twitter: ['twitter.com'],
  youtube: ['youtu.be', 'youtube.com', 'youtube-nocookie.com'],
  facebook: ['facebook.com'],
  instagram: ['instagram.com'],
};

const getProviderName = (url: URL) => {
  const host = url.host.replace('www.', '');
  const provider = Object.entries(HOSTS)
    .find(([, hosts]) => hosts.includes(host));

  return provider ? provider[0] : '';
};

export const createElemEmbed = (href: string) => {
  const url = new URL(href);
  const name = getProviderName(url);
  const elem = document.createElement('div');
  elem.classList.add('embed');
  elem.setAttribute('contentEditable', 'false');
  elem.setAttribute('data-name', name);
  elem.setAttribute('data-href', href);
  if (name === 'youtube') {
    const body = document.createElement('iframe');
    let src = 'https://www.youtube.com/embed/';
    const paths = url.pathname.split('/');

    if (url.host === 'youtu.be') {
      src += url.pathname;
    } else if (paths[paths.length - 2] === 'embed') {
      src += paths[paths.length - 1];
    } else if (url.searchParams.has('v')) {
      src += url.searchParams.get('v');
    }

    body.setAttribute('src', src);
    body.setAttribute('frameborder', '0');
    body.setAttribute('width', '720');
    body.setAttribute('height', '405');
    elem.append(body);

    return elem;
    // } if (name === 'facebook') {
    //   const body = document.createElement('div');
    //   body.setAttribute('data-href', href);
    //   let width = '500';
    //   if (window.innerWidth < 720) {
    //     width = '300';
    //   } else if (window.innerHeight <= 480) {
    //     width = '280';
    //   }
    //   body.setAttribute('data-width', width);
    //   body.classList.add('fb-post');
    //   elem.append(body);
    //   return elem;
    // } if (name === 'twitter') {
    //   const paths = url.pathname.split('/');
    //   if (paths[paths.length - 2] === 'status') {
    //     const id = paths[paths.length - 1];
    //     if (id) {
    //       const body = document.createElement('div');
    //       body.setAttribute('data-id', id);
    //       body.classList.add('tw-post');
    //       elem.append(body);
    //       return elem;
    //     }
    //   }
    // } if (name === 'instagram') {
    //   const blockquoteElement = document.createElement('blockquote');
    //   blockquoteElement.setAttribute('class', 'instagram-media');
    //   blockquoteElement.setAttribute('data-instgrm-version', '4');
    //   elem.append(blockquoteElement);

    //   const aElement = document.createElement('a');
    //   aElement.setAttribute('href', href);
    //   blockquoteElement.append(aElement);

  //   return elem;
  }
};

const embedInitAll = () => {
  const embeds = document.querySelectorAll('.embed');
  embeds.forEach((elem) => {
    const href = elem.getAttribute('data-href');
    if (href) {
      const newElem = createElemEmbed(href);
      elem.innerHTML = newElem.innerHTML;
    }
  });

  if (typeof window === 'undefined') return;

  // if (window.FB) {
  //   window.FB.init({
  //     xfbml: true,
  //     version: 'v6.0',
  //   });
  // }

  // const twitterWidgetsCreate = () => {
  //   const items = document.querySelectorAll('.tw-post');
  //   items.forEach((el) => {
  //     const id = el.getAttribute('data-id');
  //     window.twttr.widgets.createTweet(id, el);
  //   });
  // };

  // if (window.twttr?.init) {
  //   twitterWidgetsCreate();
  // } else {
  //   window.twttr?.ready(() => twitterWidgetsCreate());
  // }

  if (window.instgrm) {
    window.instgrm.Embeds.process();
  }
};

interface Props {
  post: Post;
  isPaywallReady: boolean;
  checkLimit?: boolean;
  withLikes?: boolean;
  withFooter?: boolean;
}

const ContentPost: FunctionComponent<Props> = ({
  post,
  checkLimit = true,
  isPaywallReady,
  withLikes = true,
  withFooter = true,
}) => {
  const ref = useRef<HTMLDivElement>();
  // FIXME Переделать когда нужно будет включить лимит
  // const { profile } = usePageContext();
  // const limitActive = !profile.isAuth() && checkLimit;
  const limitActive = checkLimit;
  const { data: config } = useConfig();
  const serviceText = config ? config.service_texts.article_footer : '';

  const { extendedLinksStates } = useExtendedLinks({ deps: [post.content] });

  useGuideContents({
    containerRef: ref, post, isPaywallReady,
  });
  const { isOpenStickyTableOfContents, setOpenStickyTableOfContents } = useStickyTableOfContents();
  useViewedGa();
  useScrolledGa(ref);
  const { width: windowWidth } = useWindowDimensions();
  const { guideContents } = usePageContext();

  const isGuideContentsExist = !!guideContents && !!guideContents[0]
    && !!guideContents[0].contents && !!guideContents[0].contents.length;
  const isGuideContentsVisible = windowWidth < SCREEN_MD;

  useEffect(() => {
    const t = setTimeout(() => {
      if (post.content) {
        embedInitAll();
      }
    }, 1000);
    return () => clearTimeout(t);
  }, [post.content]);

  // Этот мега костыль нужен затем, чтобы добавлять target="_blank" для ссылок,
  // потому что ссылки вставленные из ворда без target="_blank"
  let fixedContent = post.content.replace(/(<a (?:(?!target=)[^>])*)>/ig, '$1 target="_blank">');

  // А этот для сео
  fixedContent = fixedContent.replace(/(<a (?:(?!rel=)[^>])*)>/ig, '$1 rel="noreferrer">');

  const renderFooter = () => (!post.partner_material ? (
    <div className={c.container}>
      {serviceText && (
        <div className={c.wrap_footer}>
          <pre>
            <ServiceText name="article_footer" />
          </pre>
        </div>
      )}
    </div>
  ) : (
    <div style={{ height: '52px' }} />
  ));

  const renderPaywall = () => (
    <div className={c.wrap_paywall}>
      <Paywall isReady={isPaywallReady} postSlug={post.slug} />
    </div>
  );

  return (
    <>
      {extendedLinksStates.map((extendedLink, idx) => (
        <ExtendedLinkTooltip extendedLink={extendedLink} key={idx} />
      ))}
      {isGuideContentsExist && isGuideContentsVisible && (
        <>
          <StickyButton
            isOpenStickTableOfContents={isOpenStickyTableOfContents}
            setOpenStickyTableOfContents={setOpenStickyTableOfContents}
          />
          <StickyTableOfContents
            isOpen={isOpenStickyTableOfContents}
            setOpen={setOpenStickyTableOfContents}
          />
        </>
      )}
      <div ref={ref} className={clsx(c.container, { [c.limit]: isPaywallReady })}>
        <div
          className={c.wrap}
          dangerouslySetInnerHTML={{ __html: fixedContent }}
        />
      </div>
      { isPaywallReady ? renderPaywall() : withFooter ? renderFooter() : null }
      { post.status === 'published' && withLikes && (
        <div className={c.social_group}>
          <PostLike post={post} />
          {/* <SharingPost post={post} /> */}
        </div>
      )}
      { isPaywallReady && (<ModalSubscription refElement={ref} />) }
    </>
  );
};

export default ContentPost;
