import React, { FunctionComponent } from 'react';
import c from '~/components/post/ContentPost.sass';
import ServiceText from '~/components/ServiceText';
import { Page } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';

const FriendsScreen: FunctionComponent = () => {
  const canonicalLink = `${config('APP_HOST')}/friends`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="Наши друзья - Reminder"
          metaTitle="Наши друзья - Reminder"
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <div className={c.container}>
          <div className={c.wrap}>
            <ServiceText name="friends_page_text" />
          </div>
        </div>
      </Page>
    </>
  );
};

export default FriendsScreen;
