import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { SubscriptionStatus } from '~/utils/purshase-event';
import disableScroll from '~/utils/disableScroll';
import c from './PaidSubscriptionForm.sass';

export interface SubscriptionErrorProps {
  status: SubscriptionStatus;
  onClose: () => void;
}

export const SubscriptionStatusModal: FunctionComponent<SubscriptionErrorProps> = ({
  status, onClose,
}) => {
  disableScroll(true);
  const onHandleClose = () => {
    disableScroll(false);
    onClose();
  };

  const renderModal = (title: string, text: string, btnText: string) => (
    <div className={c.form}>
      <div className={c.form__content}>
        <h1 className={`${c.form__title} ${c.form__title_ok}`}>
          <span>{title}</span>
        </h1>
        <h2 className={c.form__subTitle}>{text}</h2>
      </div>
      <button
        className={`${c.form__submit} ${c.form__submit_ok}`}
        onClick={onHandleClose}
        type="button"
      >
        {btnText}
      </button>
    </div>
  );

  const renderError = () => {
    switch (status) {
      case SubscriptionStatus.PAYMENT_CHECK_PROCESSED: {
        return renderModal('Спасибо!', 'Проверьте почту', 'ОК');
      }
      case SubscriptionStatus.PAYMENT_CHECK_PENDING: {
        return renderModal('Еще немного', 'Платеж обрабатывается и скоро у вас появится подписка', 'Хорошо');
      }
      case SubscriptionStatus.PAYMENT_CHECK_FAILED: {
        return renderModal('Ошибка', 'Не удалось зачислить платёж', 'Назад');
      }
      case SubscriptionStatus.ALREADY_SUBSCRIBED: {
        return renderModal('Упс...', 'Кажется, вы уже подписаны', 'Назад');
      }
      case SubscriptionStatus.PLAN_NOT_ALLOWED: {
        return renderModal('Упс...', 'Этот тариф недоступен', 'Назад');
      }
      case SubscriptionStatus.SOMETHING_WENT_WRONG:
      default: {
        return renderModal('Упс...', 'Что-то пошло не так', 'Назад');
      }
    }
  };

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      onRequestClose={onHandleClose}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      {renderError()}
    </Modal>
  );
};
