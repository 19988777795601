import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import config from '~/config';

interface Props {
  title?: string;
  description?: string;
  metaTitle?: string;
  metaDescription?: string;
  img?: string;
  type?: string;
  link?: {
    rel: string,
    href: string
  }[],
  srcScripts?: Array<string>
}

const Helmeter: React.FunctionComponent<Props> = ({
  title,
  description,
  metaTitle,
  metaDescription,
  img,
  type,
  link,
  srcScripts = [],
}) => {
  const location = useLocation();
  const url = `${config('APP_HOST')}${location.pathname}`;
  const exactImg = img || `${config('APP_HOST')}/public/reminder.jpg`;
  const exactType = type || 'website';

  const scripts = (() => srcScripts.map((src, index) => <script src={src} key={index} />))();

  return (
    <Helmet>
      {(metaTitle || title) && (
        <title>{metaTitle || title}</title>
      )}

      {metaTitle && <meta name="title" content={metaTitle} />}
      {metaDescription && <meta name="description" content={metaDescription} />}

      {title && <meta property="og:title" content={title} />}
      {(description || metaDescription) && (
        <meta property="og:description" content={description || metaDescription} />
      )}
      <meta property="og:site_name" content="Reminder" />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={exactType} />
      <meta property="og:image" content={exactImg} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="670" />

      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={metaTitle || title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={exactImg} />

      { link && link.map(({ rel, href }) => (<link key={href} rel={rel} href={href} />)) }

      {scripts}
    </Helmet>
  );
};

Helmeter.defaultProps = {
  title: undefined,
  description: undefined,
  metaTitle: undefined,
  metaDescription: undefined,
  img: undefined,
  type: undefined,
  link: undefined,
  srcScripts: undefined,
};

export default Helmeter;
