import React, {
  ChangeEvent, Dispatch, FC, SetStateAction, useState,
} from 'react';
import Modal from 'react-modal';
import Loader from '../../Loader';
import c from '../../Reports/Form.sass';

interface Props {
  promocodeSubmit: Dispatch<string>
  open: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
  keys: {[key: string]: number}
  stateKey: number
}

interface promocodeFormData {
  promocode: string
}

const PromocodeModal: FC<Props> = ({
  promocodeSubmit,
  open,
  setOpenModal,
  keys,
  stateKey: key,
}) => {
  const [formData, setFormData] = useState<promocodeFormData>({
    promocode: '',
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>, value?: unknown) => {
    const { name, value: eventValue } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value ?? eventValue }));
  };

  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      <div className={c.form}>
        {key === keys.LOADING && (
          <div className={c.form}>
            <Loader />
          </div>
        )}
        {key !== keys.LOADING && (
          <>
            <div className={c.form__content}>
              {(key !== keys.SUCCESS) && (
                <>
                  <h1 className={`${c.form__title} ${c.form__title_ok}`}>
                    <span>Введите промокод</span>
                  </h1>
                  <div>
                    <input
                      name="promocode"
                      placeholder="Промокод"
                      aria-label="Промокод"
                      value={formData.promocode}
                      onChange={onChange}
                      className={c.form__modal_input}
                    />
                  </div>
                </>
              )}
              {(key === keys.SOMETHING_WENT_WRONG) && (
                <h2 className={c.form__subTitle}>
                  Что-то пошло не так
                </h2>
              )}
              {(key === keys.NOT_FOUND) && (
                <h2 className={c.form__subTitle}>
                  Промокод не найден
                </h2>
              )}
              {(key === keys.ALREADY_SUBSCRIBED) && (
                <h2 className={c.form__subTitle}>
                  У вас уже есть активная подписка
                </h2>
              )}
              {(key === keys.EXPIRED) && (
                <h2 className={c.form__subTitle}>
                  Срок действаия промокода закончился
                </h2>
              )}
              {(key === keys.ALREADY_USED) && (
                <h2 className={c.form__subTitle}>
                  Вы уже использовали данный промокод
                </h2>
              )}
              {(key === keys.SUCCESS) && (
                <h2 className={c.form__subTitle}>
                  Вы успешно подписались!
                </h2>
              )}
            </div>
            <div className={`${c.form__button_block}`}>
              {key !== keys.SUCCESS && (
                <>
                  <button
                    className={`${c.form__submit} ${c.form__submit_ok} ${c.form__submit_red}`}
                    onClick={() => {
                      promocodeSubmit(formData.promocode);
                    }}
                    type="button"
                  >
                    Применить
                  </button>
                  <button
                    className={`${c.form__submit} ${c.form__submit_ok}`}
                    onClick={() => setOpenModal(false)}
                    type="button"
                  >
                    Закрыть
                  </button>
                </>
              )}
              {key === keys.SUCCESS && (
                <a href="/profile">
                  <button
                    className={`${c.form__submit} ${c.form__submit_ok}`}
                    type="button"
                  >
                    Закрыть
                  </button>
                </a>
              )}
            </div>
          </>
        )}
      </div>

    </Modal>
  );
};

export default PromocodeModal;
