import React, {
  FC, useEffect, useState,
} from 'react';
import { SubscriptionPlan } from '~/resources/models';
import useSubscription from '~/resources/useSubscription';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import { useRouteMatch } from 'react-router';
import { SubscriptionStatus } from '~/utils/purshase-event';
import useProfile from '~/resources/useProfile';
import c from './PaidSubscriptionForm.sass';
import { SubscriptionFlowStep, SubscriptionType, usePageContext } from '../page';
import { SubscriptionPayment } from './SubscriptionPayment';
import { SubscriptionCheck } from './SubscriptionCheck';
import { SubscriptionStatusModal } from './SubscriptionStatusModal';

export const SubscriptionFormToAnnual: FC = () => {
  const { url } = useRouteMatch();
  const { fetchBy: fetchPlans, data: plans } = useSubscription();
  const { profile, auth } = usePageContext();
  const {
    buySubscription,
    subscriptionFlowVal,
    cancelSubscription,
    checkPayment,
  } = useSubscriptionFlow();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan | null>(null);
  const { data: profileData, fetchBy: fetchProfile } = useProfile();
  const [finalStatus, setFinalStatus] = useState<SubscriptionStatus>();
  const step = subscriptionFlowVal && subscriptionFlowVal.step;
  const [orderId, setOrderId] = useState<number>();
  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (!profileData || !profile || !profile[1]) { return; }
    profile[1](profileData);
  }, [profileData]);

  useEffect(() => {
    plans?.forEach((plan) => {
      if (plan.type === 'annual') {
        setSelectedPlan(plan);
      }
    });
  }, [plans]);

  const buy = () => {
    if (!selectedPlan) { return; }
    buySubscription(selectedPlan, SubscriptionType.NEW, url);
  };

  const onStatus = (status: SubscriptionStatus) => {
    setFinalStatus(status);
    cancelSubscription();
  };

  const onSuccessPayment = (oId: number) => {
    setOrderId(oId);
    checkPayment();
  };

  const cancel = () => {
    if (
      finalStatus === SubscriptionStatus.PAYMENT_CHECK_PROCESSED
    ) {
      fetchProfile();
      auth?.clearCache();
      setFinalStatus(undefined);
      cancelSubscription();
      window.location.reload();
    }
    setFinalStatus(undefined);
    cancelSubscription();
  };

  const renderPayment = () => (step === SubscriptionFlowStep.PAYMENT && selectedPlan
    ? (
      <SubscriptionPayment
        plan={selectedPlan}
        promocode=""
        onError={onStatus}
        onSuccess={onSuccessPayment}
        onClose={cancel}
      />
    )
    : null
  );

  const renderCheck = () => (step === SubscriptionFlowStep.CHECK && orderId && (
    <SubscriptionCheck
      orderId={orderId}
      onClose={cancel}
      onCheck={onStatus}
    />
  ));

  const renderStatus = () => (finalStatus && (
    <SubscriptionStatusModal status={finalStatus} onClose={cancel} />
  ));

  return selectedPlan ? (
    <div className={c.pay_button_wrapper}>
      {renderPayment()}
      {renderCheck()}
      {renderStatus()}
      <button onClick={buy} type="button" className={c.pay_button_single}>
        <span className={c.pay_button_title}>Перейти на годовую</span>
        <span className={c.pay_button_subtitle}>Экономия 1780 руб.</span>
      </button>
    </div>
  ) : null;
};

export default SubscriptionFormToAnnual;
