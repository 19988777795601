import React, { FunctionComponent } from 'react';

import Button from '~/components/Button/Button';

import c from './More.sass';

interface MoreProps extends React.HTMLAttributes<HTMLAnchorElement> {
    url: string,
    onClick: () => void,
}

const More: FunctionComponent<MoreProps> = ({
  className,
  url,
  onClick,
}) => (
  <a
    className={className}
    href={url}
    onClick={(e) => { e.preventDefault(); onClick(); }}
  >
    <Button variant="secondary" size="large" className={c.button_more}>
      Еще немного
    </Button>
  </a>
);

More.displayName = 'More';

export default More;
