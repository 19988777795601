import React, { FunctionComponent, useEffect } from 'react';
import { useConfig } from '~/resources';

interface Props {
    name: string,
}

const ServiceText: FunctionComponent<Props> = ({ name }) => {
  const { data: config, fetchBy } = useConfig();

  useEffect(() => {
    fetchBy();
  }, []);

  const text = config ? config.service_texts[name] : '';
  if (!text) {
    return null;
  }
  return (
    <span
      style={{ whiteSpace: 'pre-wrap' }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default ServiceText;
