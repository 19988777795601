import React from 'react';

import Title from '~/components/typography/Title';
import Paragraph from '~/components/typography/Paragraph';
import clsx from 'clsx';
import c from './PostCarouselCard.sass';

type TProps = {
  title: string;
  description: string;
  imageSrc: string;
  href: string;
  dark?: boolean;
};

export default function PostCarouselCard(props: TProps) {
  const {
    title, description, imageSrc, href, dark,
  } = props;

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={clsx(c.post_carousel_card, {
        [c.post_carousel_card_dark]: dark,
      })}
      >
        {dark && <div className={c.post_carousel_card__image_bg} style={{ backgroundImage: `url(${imageSrc})` }} />}
        <div
          className={clsx(c.post_carousel_card__image, {
            [c.post_carousel_card__image_dark]: dark,
          })}
          style={{ backgroundImage: dark ? '' : `url(${imageSrc})` }}
        />
        <div>
          <Title level={4} className={c.post_carousel_card__title}>{title}</Title>
          <Paragraph size="small" className={c.post_carousel_card__description}>{description}</Paragraph>
        </div>
      </div>
    </a>
  );
}
