import React from 'react';

import { TAuthor } from '~/resources/models';
import Paragraph from '~/components/typography/Paragraph';
import NoPicture from '~/components/icons/NoPicture';
import c from './AuthorItem.sass';

type TProps = {
  author: TAuthor;
};

export default function AuthorItem(props: TProps) {
  const { author } = props;

  const { name, position, image } = author;

  return (
    <div className={c.author_item}>
      {image
        ? <img src={image.src} alt={image.alt ?? name} className={c.author_item__avatar} />
        : <div className={c.author_item__avatar}><NoPicture /></div>}
      <div>
        <Paragraph weight="bold" className={c.author_item__name}>{name}</Paragraph>
        {position && <Paragraph variant="caption">{position}</Paragraph>}
      </div>
    </div>
  );
}
