import React, { FunctionComponent } from 'react';

const GiftIcon: FunctionComponent = () => (

  <svg width="360" height="264" viewBox="0 0 360 264" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M280.164 84.13C307.07 148.651 288.389 218.611 239.383 240.806C190.435 262.975 128.669 229.547 101.764 165.028C74.8584 100.507 93.5386 30.5471 142.545 8.35153C191.493 -13.8174 253.259 19.611 280.164 84.13Z" stroke="#F85B2E" strokeWidth="3" />
    <path d="M334.572 156.864C320.435 173.712 302.901 184.909 286.04 189.44C269.174 193.973 253.128 191.814 241.684 182.211C230.239 172.607 225.326 157.18 226.861 139.783C228.396 122.392 236.378 103.18 250.515 86.3313C264.653 69.4827 282.187 58.2862 299.048 53.7549C315.914 49.222 331.96 51.3811 343.404 60.9843C354.849 70.5875 359.762 86.0146 358.226 103.412C356.692 120.803 348.71 140.015 334.572 156.864Z" stroke="#25D3FF" strokeWidth="3" />
    <path d="M231.953 82.256L247.828 73.4452L231.763 64.7326C228.952 63.2688 228.006 62.0194 228.664 59.9506C228.883 59.2614 229.276 58.6259 229.904 57.8769L238.2 46.2778C239.613 44.2605 240.815 43.5038 242.39 44.0042C243.263 44.2819 244.2 44.9584 245.373 46.0915L258.968 58.3859L262.346 40.4778C262.337 39.9063 262.502 39.3884 262.721 38.6992C263.543 36.1125 264.864 35.5833 268.185 36.6391L281.98 41.0243C284.946 41.9673 286.018 43.4472 285.197 46.0325L284.521 47.527L276.948 64.1016L294.964 61.8562C296.585 61.6111 297.741 61.5999 298.614 61.8776C300.007 62.3201 300.733 63.6901 300.713 66.15L300.97 80.4681C301.051 81.4427 301.004 82.1882 300.785 82.8774C300.128 84.9462 298.625 85.4175 295.494 84.9924L277.165 82.771L285.211 99.1864C286.056 100.784 286.364 102.211 286.035 103.245C285.652 104.451 284.404 105.383 282.501 105.917L268.806 110.295C267.303 110.767 266.32 110.834 265.446 110.557C264.054 110.114 263.455 108.974 262.893 106.518L259.153 88.6249L245.95 101.132C244.128 102.641 242.753 103.342 241.524 102.952C240.651 102.674 239.714 101.996 238.769 100.747L230.289 89.3196C229.098 87.612 228.553 86.2999 228.937 85.0943C229.265 84.0598 230.35 83.0755 231.953 82.256Z" fill="#974DE0" />
    <path d="M145.743 81.5879H196.184" stroke="#25D3FF" strokeWidth="3" />
    <path d="M160.263 98.5293L221.403 98.5293" stroke="#25D3FF" strokeWidth="3" />
    <path d="M125.872 115.69L196.183 115.69" stroke="#25D3FF" strokeWidth="3" />
    <rect x="190.168" y="56.6816" width="152.85" height="145.208" rx="4" transform="rotate(15 190.168 56.6816)" fill="#F85B2E" />
    <rect x="187.922" y="53.5645" width="157.701" height="30.9936" rx="4" transform="rotate(15 187.922 53.5645)" fill="#FF764E" />
    <ellipse cx="244.51" cy="144.046" rx="18.3421" ry="74.6475" transform="rotate(15 244.51 144.046)" fill="#974DE0" />
    <circle cx="292.448" cy="211.238" r="42.7981" fill="#25D3FF" />
    <path d="M176.813 181.911L230.191 263.814H0L176.813 181.911Z" fill="#974DE0" />
  </svg>
);

export default GiftIcon;
