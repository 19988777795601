import React from 'react';

import { usePageContext } from '~/components';
import ProfileIcon from '~/components/icons/Profile';
import Title from '~/components/typography/Title';

import Paragraph from '~/components/typography/Paragraph';
import StarIcon from '~/components/icons/Star';
import Badge from '~/components/Badge/Badge';
import { Link } from 'react-router-dom';
import c from './ProfileHeader.sass';

export default function ProfileHeader() {
  const { profile } = usePageContext();

  const isProfile = profile && profile[0];
  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;

  if (isProfile) {
    return (
      <div className={c.profile}>
        <Link to="/profile">
          <div className={c.profile__title}>
            <ProfileIcon />
            <Title level={4}>Профиль</Title>
          </div>
        </Link>
        <Badge primary={isSubscribed}>
          <Paragraph variant="caption" size="small" className={c.badge_text}>
            {isSubscribed ? (
              <div className={c.reminder_badge_content}>
                <StarIcon />
                REMINDER
              </div>
            ) : 'FREE'}
          </Paragraph>
        </Badge>
      </div>
    );
  }

  return null;
}
