import React from 'react';

import Title from '~/components/typography/Title';
import { usePageContext } from '~/components';
import GuideContentItem from '~/components/page/guideSidebar/GuideContentItem';
import { useParams } from 'react-router';
import c from './GuideContents.sass';

export default function GuideContents() {
  const { guideContents } = usePageContext();
  const { slug } = useParams<{ slug: string }>();

  const isGuideContentsExist = guideContents && guideContents[0]
    && guideContents[0].contents && guideContents[0].contents.length;

  if (!isGuideContentsExist || slug !== guideContents[0]?.slug) return null;

  const contents = guideContents[0]?.contents ?? [];
  const isActive = guideContents[0]?.activeIdx ?? -1;

  return (
    <div className={c.guide_contents}>
      <Title level={4}>Оглавление</Title>
      {contents.map((content, idx) => (
        <GuideContentItem text={content} isActive={isActive === idx} idx={idx} key={idx} />
      ))}
    </div>
  );
}
