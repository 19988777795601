import React, {
  ChangeEvent, FC, FormEvent, useEffect, useState,
} from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import anyFetch from '~/resources/anyFetch';
import config from '~/config';
import validatePassword from '~/utils/password';
import useProfile from '~/resources/useProfile';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import disableScroll from '~/utils/disableScroll';
import c from './Auth.sass';
import { EyeCloseIcon, EyeOpenIcon } from './Icons';
import {
  AlertModalType, AuthModalFrom, AuthModalState, usePageContext,
} from '../page/context.page';
import Loader from '../Loader';
import { SubscriptionFlowStep } from '../page';

interface CheckFormData {
  email: string
  password: string
}

interface Props {
  _email: string,
  from: AuthModalFrom,
}

const SignupForm: FC<Props> = ({ _email, from }) => {
  const { url } = useRouteMatch();
  const {
    authModal,
    auth,
    alertModal,
    profile: profileState,
  } = usePageContext();
  const history = useHistory();
  const [formData, setFormData] = useState<CheckFormData>({
    email: _email,
    password: '',
  });
  const { subscriptionFlowVal, buyAfterAuth } = useSubscriptionFlow();
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(true);
  const { data: profile, fetchBy: fetchProfile } = useProfile();
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setProfile = profileState && profileState[1];

  const onChange = (e: ChangeEvent<HTMLInputElement>, value?: unknown) => {
    const { name, value: eventValue } = e.target;
    setShowError(false);
    setFormData((prev) => ({ ...prev, [name]: value ?? eventValue }));
  };

  useEffect(() => {
    setIsPasswordInvalid(!validatePassword(formData.password));
  }, [formData]);

  useEffect(() => {
    if (!profile || !setProfile) { return; }
    setProfile(profile);
    disableScroll(false);
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.NONE });
    }
    if (
      subscriptionFlowVal
      && subscriptionFlowVal.step === SubscriptionFlowStep.AUTH
      && url === subscriptionFlowVal.subscriptionStartUrl
    ) {
      buyAfterAuth();
    } else {
      history.push('/profile');
    }
  }, [profile]);

  const onRegisterError = () => {
    setShowError(false);
  };

  const onSuccessAuth = (data: any) => {
    if (!auth) { return; }
    auth.setAuth(data);
    fetchProfile();
  };

  const onRegisterSuccess = (email: string) => {
    if (!alertModal || !authModal) { return; }
    alertModal[1]({ alertType: AlertModalType.CONFIRM, email });
    authModal[1]({ authModalState: AuthModalState.NONE });
    disableScroll(true);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = formData;

    setShowError(isPasswordInvalid);
    if (isPasswordInvalid) {
      return;
    }

    setIsLoading(true);
    const res = await anyFetch(`${config('API_HOST')}/auth/register`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        name: email,
        password,
        password_confirmation: password,
        client_id: config('CLIENT_ID'),
        client_secret: config('CLIENT_SECRET'),
        isSubscription: from !== AuthModalFrom.NONE,
      }),
    });
    const data = await res.json();

    setIsLoading(false);

    if (data.result === 'ok') {
      onRegisterSuccess(email);
    } else if (data.access_token) {
      onSuccessAuth(data);
    } else {
      onRegisterError();
    }
    // ToDo: handle case when purchase subscription
    // if (auth && data.access_token) {
    //   auth.setAuth(data);
    //   if (from === AuthModalFrom.PAYWALL) {
    //     history.push('/subscription-step');
    //     window.location.reload();
    //     return;
    //   }
    //   window.location.reload();
    //   return;
    // }
  };

  return (
    <>
      <div className={c.title}>Придумайте пароль</div>
      <form
        className={c.form}
        onSubmit={onSubmit}
      >
        <input
          disabled
          type="email"
          name="email"
          placeholder="Ваш e-mail"
          aria-label="Email"
          value={formData.email}
          className={c.inputText}
          required
        />
        <div
          className={showError ? `${c.inputText_password} ${c.inputText_invalid}` : c.inputText_password}
        >
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Пароль"
            aria-label="Password"
            value={formData.password}
            className={c.fullWidth}
            onChange={onChange}
            required
          />
          {showPassword ? (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(false)}
            >
              <EyeOpenIcon />
            </button>
          ) : (
            <button
              type="button"
              className={c.icon_eye}
              onClick={() => setShowPassword(true)}
            >
              <EyeCloseIcon />
            </button>
          )}
        </div>
        <span
          className={c.info}
        >
          Пароль должен содержать не менее 8 символов,
          в том числе хотя бы одну цифру и заглавную букву
        </span>
        <button
          type="submit"
          aria-label="Зарегистрироваться"
          className={
            isPasswordInvalid
              ? `${c.btn} ${c.btn_disabled}`
              : `${c.btn} ${c.btn_enabled}`
          }
        >
          Зарегистрироваться
        </button>

        <div className={c.service_text}>
          Регистрируясь, вы принимаете условия
          <br />
          <Link to="/privacy_policy" target="_blank"><u>пользовательского соглашения</u></Link>
        </div>
      </form>
      {isLoading && (
        <div className={c.modal__loading}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default SignupForm;
