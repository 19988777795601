import React, { FC, useRef } from 'react';
import c from './GiftPage.sass';
import GiftForm from '../Gift/GiftForm';
import {
  OneIcon, ThreeIcon, TwoIcon, GiftIcon, BgGeomIcon,
} from '../Gift/Icons';

const steps = [
  {
    title: 'Укажите свою почту',
    text: 'Отправим вам чек и сертификат после покупки',
    icon: (<OneIcon />),
  },
  {
    title: 'Оплатите подписку',
    text: 'Можно только российской картой',
    icon: (<TwoIcon />),
  },
  {
    title: 'Подарите сертификат',
    text: 'Перешлите ссылку на сертификат или дарите распечатанный',
    icon: (<ThreeIcon />),
  },
];

const GiftPage: FC = () => {
  const myRef = useRef<null | HTMLDivElement>(null);
  return (
    <div className={c.gift}>
      <div className={c.gift_header}>
        <div>
          <div className={c.gift_title}>
            Подписка на год в подарок
          </div>
          <div className={c.gift_text}>
            Подарите близким новые знания о здоровье, саморазвитии и счастливой жизни
          </div>
          <button
            type="button"
            className={`${c.gift_submit} ${c.gift_submit_enabled}`}
            onClick={() => myRef.current?.scrollIntoView()}
          >
            Подарить подписку
          </button>
          <div className={c.gift_subtitle}>
            Всего три шага к подарку
          </div>
        </div>
        <div className={c.gift_header_icon}>
          <GiftIcon />
        </div>
      </div>
      <div className={c.gift_steps}>
        {steps.map((step) => (
          <div
            key={step.text}
            className={c.gift_steps_item}
          >
            <div>{step.icon}</div>
            <div>
              <p>{step.title}</p>
              <span>{step.text}</span>
            </div>
          </div>
        ))}
      </div>
      <div ref={myRef} />
      <div className={c.line} />

      <div className={c.gift_content}>
        <GiftForm />
        <div>
          <div className={c.gift_content_icon}> </div>
          <BgGeomIcon />
        </div>
        <div> </div>
      </div>

      <div className={c.line} />
    </div>
  );
};

export default GiftPage;
