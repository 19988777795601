import React, { FunctionComponent } from 'react';

const BgPdfIcon: FunctionComponent = () => (

  <svg width="595" height="842" viewBox="0 0 595 842" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6335_4435)">
      <rect width="595" height="842" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M485.418 347.68C605.777 335.417 698.968 282.479 693.564 229.439C688.16 176.398 586.209 143.341 465.849 155.603C345.489 167.865 252.299 220.803 257.702 273.844C263.106 326.885 365.058 359.942 485.418 347.68Z" fill="#974DE0" />
      <path d="M802.751 790.938C829.445 824.242 838.592 856.21 832.32 880.954C826.078 905.577 804.41 923.599 768.152 928.782C731.855 933.97 688.073 925.232 646.619 906.791C605.179 888.355 566.223 860.291 539.554 827.017C512.861 793.712 503.713 761.744 509.985 737.001C516.227 712.378 537.895 694.355 574.153 689.173C610.45 683.985 654.232 692.722 695.686 711.164C737.126 729.6 776.082 757.663 802.751 790.938Z" stroke="#25D3FF" strokeWidth="3" />
      <circle cx="363.507" cy="827.935" r="78.3694" transform="rotate(-18.8734 363.507 827.935)" fill="#25D3FF" />
      <path d="M169.878 807.371C196.571 840.676 205.719 872.644 199.447 897.388C193.205 922.01 171.537 940.033 135.279 945.215C98.9815 950.404 55.2003 941.666 13.7464 923.224C-27.694 904.789 -66.6503 876.725 -93.3193 843.45C-120.013 810.146 -129.16 778.178 -122.888 753.434C-116.646 728.812 -94.9782 710.789 -58.7202 705.606C-22.4226 700.418 21.3586 709.156 62.8126 727.598C104.253 746.033 143.209 774.097 169.878 807.371Z" stroke="#25D3FF" strokeWidth="3" />
      <path d="M428.577 50.753L450.718 27.2865L418.821 21.181C413.273 20.2153 411 18.6085 411 14.7511C411 13.466 411.322 12.1785 411.984 10.5716L419.789 -13.5362C421.095 -17.7129 422.724 -19.6416 425.659 -19.6416C427.287 -19.6416 429.239 -19.0005 431.834 -17.7129L461.474 -4.21179L457.555 -36.3564C457.233 -37.3194 457.233 -38.2851 457.233 -39.57C457.233 -44.3932 459.184 -46 465.376 -46H491.097C496.628 -46 499.241 -44.0713 499.241 -39.2508L498.902 -36.3564L494.999 -4.21179L524.3 -17.7129C526.913 -19.0005 528.864 -19.6416 530.492 -19.6416C533.088 -19.6416 535.056 -17.7129 536.346 -13.5362L544.489 10.5716C545.151 12.1785 545.473 13.466 545.473 14.7511C545.473 18.6085 543.183 20.2153 537.652 21.181L505.416 27.2865L527.88 50.753C530.17 53.0037 531.459 55.2543 531.459 57.183C531.459 59.431 529.848 61.6817 526.913 63.6104L506.078 78.3964C503.788 80.0032 502.159 80.6471 500.53 80.6471C497.934 80.6471 496.306 79.0402 494.032 75.1826L478.067 46.8956L462.441 75.1826C460.168 78.7182 458.217 80.6471 455.926 80.6471C454.297 80.6471 452.346 80.0032 450.073 78.3964L429.561 63.6104C426.626 61.3597 424.997 59.431 424.997 57.183C424.997 55.2543 426.303 53.0037 428.577 50.753Z" fill="#F85B2E" />
    </g>
  </svg>

);

export default BgPdfIcon;
