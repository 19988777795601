import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  usePageContext, Page, Loader, AlertModalType, AuthModalState,
} from '~/components';
import Banner from '~/components/Banner';
import anyFetch from '~/resources/anyFetch';
import config from '~/config';
import useProfile from '~/resources/useProfile';
import disableScroll from '~/utils/disableScroll';

const ChangepasswordScreen: FunctionComponent = () => {
  const { key } = useParams<{ key: string }>();
  const {
    auth, alertModal, authModal,
  } = usePageContext();
  const { fetchBy: fetchProfile } = useProfile();

  useEffect(() => {
    window.scroll({ top: 0 });
  });

  const showChangepasswordModal = () => {
    if (authModal && alertModal) {
      disableScroll(true);
      authModal[1]({ authModalState: AuthModalState.CHANGE_PASSWORD, urlKey: key });
      alertModal[1]({ alertType: AlertModalType.NONE });
    }
  };

  const fetchVerifyProfile = async () => {
    const url = `${config('API_HOST')}/oauth/token`.replace('/api', '');
    const res = await anyFetch(url, {
      method: 'POST',
      body: JSON.stringify({
        key,
        grant_type: 'change_password',
        client_id: config('CLIENT_ID'),
        client_secret: config('CLIENT_SECRET'),
      }),
    });
    const resData = await res.json();
    if (resData.access_token && auth) {
      auth.setAuth(resData);
      fetchProfile();
      showChangepasswordModal();
    } else if (alertModal) {
      const alertType = res.status === 403
        ? AlertModalType.PASSWORD_CHANGE_FAIL
        : AlertModalType.ERROR;
      alertModal[1]({ alertType });
      disableScroll(true);
    }
  };

  useEffect(() => {
    fetchVerifyProfile();
  }, []);

  return (
    <>
      <Banner />
      <Page>
        <Loader />
      </Page>
    </>
  );
};

export default ChangepasswordScreen;
