import React, { FunctionComponent } from 'react';

const BooksBenefitIcon: FunctionComponent = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/Books">
      <rect id="Rectangle 103" x="8" y="14" width="21" height="6" fill="#F85B2E" />
      <rect id="Rectangle 104" x="3" y="22" width="21" height="6" fill="#F85B2E" />
      <rect id="Rectangle 105" x="11" y="30" width="21" height="6" fill="#F85B2E" />
      <path id="Vector 18 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M33 13.5H14V10.5H33V13.5Z" fill="#25D3FF" />
      <path id="Vector 19 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M32 29.5H13V26.5H32V29.5Z" fill="#25D3FF" />
      <path id="Vector 20 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M22 21.5H3V18.5H22V21.5Z" fill="#25D3FF" />
    </g>
  </svg>
);

export default BooksBenefitIcon;
