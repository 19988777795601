import React from 'react';

export default function ArrowRightFilled() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 14.3904L15.3904 9.99998L11 5.6096L10.2096 6.39998L13.2423 9.43268H4.43268V10.5673H13.2423L10.2096 13.6L11 14.3904ZM10.0016 19.5C8.69797 19.5 7.46966 19.2506 6.31673 18.752C5.16379 18.2533 4.15555 17.5724 3.292 16.7092C2.42843 15.846 1.74721 14.8382 1.24833 13.6858C0.749442 12.5334 0.5 11.3053 0.5 10.0017C0.5 8.68772 0.749334 7.45268 1.248 6.29655C1.74667 5.1404 2.42758 4.13472 3.29075 3.2795C4.15393 2.42427 5.16173 1.74721 6.31415 1.24833C7.46658 0.749443 8.69463 0.5 9.9983 0.5C11.3122 0.5 12.5473 0.749334 13.7034 1.248C14.8596 1.74667 15.8652 2.42342 16.7205 3.27825C17.5757 4.1331 18.2527 5.13834 18.7516 6.29398C19.2505 7.44959 19.5 8.68437 19.5 9.9983C19.5 11.302 19.2506 12.5303 18.752 13.6832C18.2533 14.8362 17.5765 15.8444 16.7217 16.708C15.8669 17.5715 14.8616 18.2527 13.706 18.7516C12.5504 19.2505 11.3156 19.5 10.0016 19.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
