import React, { FunctionComponent } from 'react';

const OneIcon: FunctionComponent = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="38" height="38" rx="19" fill="white" />
    <rect x="1" y="1" width="38" height="38" rx="19" stroke="#F85B2E" strokeWidth="2" />
    <path d="M22.0698 24.8H25.1298V27H16.0698V24.8H19.6698V15.88L16.4698 17.64L15.3298 15.7L19.3498 13.4H22.0698V24.8Z" fill="#F85B2E" />
  </svg>
);

export default OneIcon;
