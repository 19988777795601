import React, { FunctionComponent, useEffect } from 'react';
import { Page, Paginator } from '~/components';
import PostList from '~/components/post/PostList';
import { useConfig } from '~/resources';
import config from '~/config';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';
import { PostType } from '~/resources/PostType';
import Helmeter from './Helmeter';

const HomeScreen: FunctionComponent = () => {
  const { fetchBy } = useConfig();

  useEffect(() => {
    fetchBy();
  }, []);

  const canonicalLink = config('APP_HOST');

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <JsonLd />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <PostList
              isMainPage
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
              params={{ type: [PostType.ARTICLE, PostType.GUIDE], page: String(page) }}
              noDividersIdx={() => [1, 2]}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

export default HomeScreen;
