import React, { FunctionComponent } from 'react';
import { MailingStatus } from '~/resources/useMailing';
import c from './ModalSubscription.sass';

interface Props {
  status: MailingStatus;
}

const SubscriptionMessagesModel: FunctionComponent<Props> = ({ status }) => {
  switch (status) {
    case MailingStatus.OK: {
      return (
        <div className={c.form}>
          <h2 className={c.form__title_subscription}>
            <span>Есть. Следующее наше письмо </span>
            <span>будет у вас в почте!</span>
          </h2>
        </div>
      );
    }
    case MailingStatus.EXISTS: {
      return (
        <div className={c.form}>
          <h2 className={c.form__title_error}>
            Кажется, вы уже подписаны.
          </h2>
          <p className={c.form__description}>
            <span>Если нет, то напишите нам:</span>
            {' '}
            <a href="mailto:newsletter@reminder.media">
              <u>newsletter@reminder.media</u>
            </a>
          </p>
        </div>
      );
    }
    case MailingStatus.ERROR: {
      return (
        <div className={c.form}>
          <h2 className={c.form__title_error}>
            Что-то пошло не так.
          </h2>
          <p className={c.form__description}>
            <span>Напишите нам, если не удалось подписаться:</span>
            {' '}
            <a href="mailto:newsletter@reminder.media">
              <u>newsletter@reminder.media</u>
            </a>
          </p>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default SubscriptionMessagesModel;
