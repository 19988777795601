import React, { FunctionComponent } from 'react';

const GiftIcon: FunctionComponent = () => (

  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.373 3.10042C45.8081 3.42802 46.9473 4.51737 47.3388 5.9364L49.6014 14.1386L52.0235 5.87505C52.4283 4.49413 53.5436 3.43705 54.9442 3.10685C56.3448 2.77666 57.8148 3.22426 58.7938 4.27897L69.9317 16.279C70.8 17.2144 71.1697 18.5081 70.9269 19.7611C70.6841 21.014 69.858 22.076 68.7031 22.6194L51.7031 30.6194C51.0021 30.9493 50.2379 31.0617 49.4999 30.9688C48.7306 31.0656 47.9338 30.9391 47.2112 30.5778L31.2112 22.5778C30.1036 22.024 29.3149 20.9884 29.0755 19.7735C28.8361 18.5585 29.1729 17.3012 29.9876 16.3685L40.4703 4.36855C41.4388 3.25994 42.9379 2.77283 44.373 3.10042ZM39.4158 17.7358L42.7556 19.4058L41.604 15.2309L39.4158 17.7358ZM10 56.0001C7.79086 56.0001 6 54.2093 6 52.0001C6 51.8308 6.01052 51.6639 6.03095 51.5001C6.01052 51.3363 6 51.1694 6 51.0001L6 46.0001C6 34.9544 14.9543 26.0001 26 26.0001H28.5C30.7091 26.0001 32.5 27.791 32.5 30.0001C32.5 32.2093 30.7091 34.0001 28.5 34.0001H26C19.3726 34.0001 14 39.3727 14 46.0001L14 48.0001H83V46.0001C83 39.3727 77.6274 34.0001 71 34.0001H70C67.7909 34.0001 66 32.2093 66 30.0001C66 27.791 67.7909 26.0001 70 26.0001H71C82.0457 26.0001 91 34.9544 91 46.0001V51.0001C91 51.1695 90.9895 51.3363 90.969 51.5001C90.9895 51.6639 91 51.8308 91 52.0001C91 54.2093 89.2091 56.0001 87 56.0001V68.0001C87 79.0458 78.0457 88.0001 67 88.0001H30C18.9543 88.0001 10 79.0458 10 68.0001V56.0001ZM73.8187 56.0001H56.6845C56.4852 56.5603 56.1575 57.0845 55.7019 57.5267L32.5475 80.0001H49.0914L73.8187 56.0001ZM45.7884 56.0001H18V68.0001C18 72.1122 20.0683 75.7411 23.2213 77.9035L45.7884 56.0001ZM60.5778 80.0001H67C73.6274 80.0001 79 74.6275 79 68.0001V62.1197L60.5778 80.0001ZM56.3382 19.5966L60.3457 17.7107L57.7201 14.8819L56.3382 19.5966Z"
      fill="url(#paint0_linear_6093_1737)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6093_1737"
        x1="91"
        y1="53.164"
        x2="5.98952"
        y2="53.0336"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#F85B2E"
        />
        <stop
          offset="1"
          stopColor="#B688E4"
        />
      </linearGradient>
    </defs>
  </svg>
);

export default GiftIcon;
