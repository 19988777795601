import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import usePosts from '~/resources/usePosts';
import { Loader, usePageContext } from '~/components';
import { ResourceMeta, QueryParams, FetchStatus } from '~/resources';
import PreviewPost from './PreviewPost';
import { ListNews } from '../News';
import ItemList from '../ItemList/ItemList';

interface Props {
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
  params: QueryParams;
  isMainPage?: boolean | undefined,
  awaitProfile?: boolean;
  beforeElement?: ReactElement;
  getMiddleElements?: (isSubscribed?: boolean) => Record<number, ReactElement> | undefined;
  noDividersIdx?: (isSubscribed?: boolean) => number[] | undefined;
}

const PostList: FunctionComponent<Props> = ({
  page,
  onMeta,
  onLoading,
  params,
  isMainPage,
  awaitProfile = false,
  beforeElement,
  getMiddleElements,
  noDividersIdx,
}) => {
  const {
    meta, fetchBy, data: posts,
  } = usePosts(params, {}, isMainPage ? 'main_page' : undefined);

  const { profileStatus } = usePageContext();
  const status = profileStatus?.[0] ?? undefined;

  const { profile } = usePageContext();
  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;

  const isLoading = !posts || (awaitProfile && (!status || status === FetchStatus.Fetching));

  useEffect(() => {
    fetchBy();
  }, [page]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  const renderItems = () => {
    const items: ReactElement[] = [];
    const middleElements = getMiddleElements?.(isSubscribed);

    if (!posts) return items;

    posts.forEach((post, i) => {
      if (page === 1 && middleElements && middleElements[i]) {
        items.push(middleElements[i]);
      }

      if (page === 1 && i === 2 && isMainPage) {
        items.push(<ListNews />);
      }

      items.push(<PreviewPost post={post} />);
    });

    return items;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {page === 1 && beforeElement}
      <ItemList
        className={isMainPage ? 'onMainPage' : ''}
        items={renderItems()}
        noDividersIdx={noDividersIdx?.(isSubscribed)}
      />
    </>
  );
};

PostList.defaultProps = {
  isMainPage: false,
};

export default PostList;
