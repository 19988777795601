import React, { FunctionComponent } from 'react';

const EyeCloseIcon: FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      fill="white"
    />
    <path
      d="M10.2676 13C10.0974 12.7058 10 12.3643 10 12C10 10.8954 10.8954 10 12 10C12.7625 10 13.4254 10.4267 13.7628 11.0544L10.2676 13Z"
      fill="black"
    />
    <rect
      x="2"
      y="16.2064"
      width="21.5925"
      height="2"
      rx="1"
      transform="rotate(-28.2085 2 16.2064)"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 14 17.5228 18 12 18C10.6575 18 9.37683 17.7932 8.20721 17.4442L11.0811 15.894C11.3762 15.9633 11.6838 16 12 16C13.7277 16 15.1995 14.9047 15.759 13.3706L21.3256 10.3678C21.7611 10.9693 22 11.538 22 12ZM19.8909 8.87111L15.8824 11.0334C15.4501 9.29124 13.8758 8 12 8C9.79086 8 8 9.79086 8 12C8 13.0471 8.40231 14.0002 9.06078 14.7131L5.81579 16.4636C3.49197 15.2196 2 13.4432 2 12C2 9.5 6.47715 6 12 6C15.2067 6 18.061 7.34854 19.8909 8.87111Z"
      fill="black"
    />
  </svg>

);

export default EyeCloseIcon;
