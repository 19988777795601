import React from 'react';

export default function CloseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.2729 18.527L5.4729 17.727L11.1999 12L5.4729 6.27302L6.2729 5.47302L11.9999 11.2L17.7269 5.47302L18.5269 6.27302L12.7999 12L18.5269 17.727L17.7269 18.527L11.9999 12.8L6.2729 18.527Z"
        fill="currentColor"
      />
    </svg>
  );
}
