import React, { useEffect, useRef, useState } from 'react';

import { TExtendedLinkState } from '~/components/post/extendedLink/types';
import { useOnClickOutside } from '~/utils/hooks/useOnClickOutside';
import ExtendedLinkIcon from '~/components/icons/ExtendedLink';
import useWindowDimensions from '~/utils/useWindowDimensions';
import { useDebounce } from '~/utils/hooks/useDebounce';
import { URL_PROTOCOL_REGEX } from '~/utils/regex';
import { getNewLeftPos, getNewTopPos } from '~/components/post/extendedLink/utils/tooltipPos';
import c from './ExtendedLinkTooltip.sass';

type TTooltipOpacity = '0' | '1';

type TProps = {
  extendedLink: TExtendedLinkState;
};

export default function ExtendedLinkTooltip(props: TProps) {
  const { extendedLink } = props;
  const {
    isOpen, url, description, extendedLinkLabel, close,
  } = extendedLink;

  const [topPos, setTopPos] = useState<number>();
  const [leftPos, setLeftPos] = useState<number>();

  const tooltipRef = useRef<HTMLDivElement>(null);

  const urlString = url.replace(URL_PROTOCOL_REGEX, '');

  const { width: windowWidth } = useWindowDimensions();
  const isOpenDebounced = useDebounce(isOpen, 0);

  const [opacity, setOpacity] = useState<TTooltipOpacity>('0');

  useEffect(() => {
    if (isOpen) {
      const rect = extendedLinkLabel.getBoundingClientRect();

      setTopPos(getNewTopPos(rect));
      setLeftPos(getNewLeftPos(rect, windowWidth));

      return;
    }

    setTopPos(0);
    setLeftPos(0);
  }, [isOpen, windowWidth]);

  useEffect(() => {
    if (isOpen) {
      setOpacity('1');

      return;
    }

    setOpacity('0');
  }, [isOpenDebounced]);

  useOnClickOutside(tooltipRef, close);

  const handleTooltipClick = () => {
    window.open(url, '_blank')?.focus();
  };

  if (!isOpen || !topPos || !leftPos) return null;

  return (
    <div
      className={c.extended_link_tooltip}
      style={{ top: topPos, left: leftPos, opacity }}
      ref={tooltipRef}
      onClick={handleTooltipClick}
    >
      <div className={c.text_container}>
        <p className={c.text_container__description}>{description}</p>
        <p className={c.text_container__url}>{urlString}</p>
      </div>
      <div className={c.icon_container}>
        <ExtendedLinkIcon />
      </div>
    </div>
  );
}
