import React from 'react';

export default function StarOutlinedIcon() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6524 86.4665C39.4563 88.5443 44.5742 89.5832 50.0062 89.5832C55.4809 89.5832 60.6259 88.5438 65.4409 86.4651C70.2561 84.3865 74.4446 81.548 78.0065 77.9498C81.5683 74.3517 84.388 70.1507 86.4658 65.3468C88.5436 60.5429 89.5825 55.425 89.5825 49.9929C89.5825 44.5182 88.5432 39.3733 86.4645 34.5583C84.3858 29.7431 81.5647 25.5546 78.0012 21.9927C74.4378 18.4309 70.2475 15.6111 65.4302 13.5334C60.613 11.4556 55.467 10.4167 49.9923 10.4167C44.5603 10.4167 39.4434 11.456 34.6416 13.5347C29.8399 15.6134 25.6407 18.4345 22.0441 21.9979C18.4476 25.5613 15.6105 29.7517 13.5327 34.569C11.4549 39.3862 10.416 44.5322 10.416 50.0069C10.416 55.4388 11.4554 60.5557 13.534 65.3575C15.6127 70.1593 18.4512 74.3584 22.0494 77.955C25.6475 81.5516 29.8485 84.3887 34.6524 86.4665ZM40.6221 53.2777L33.0758 60.9982C32.2978 61.7386 31.8542 62.4791 31.8542 63.1136C31.8542 63.8532 32.4099 64.4878 33.4083 65.2282L40.3998 70.0928C41.1768 70.6215 41.8427 70.8333 42.3975 70.8333C43.1745 70.8333 43.8395 70.1987 44.6175 69.0355L49.9447 59.7291L55.3821 69.0355C56.1592 70.3046 56.7139 70.8333 57.6021 70.8333C58.156 70.8333 58.7116 70.6215 59.4887 70.0928L66.5913 65.2282C67.5897 64.5937 68.1445 63.8532 68.1445 63.1136C68.1445 62.4791 67.7008 61.7386 66.9238 60.9982L59.2664 53.2777L70.2533 51.269C72.1399 50.9513 72.916 50.4227 72.916 49.1536C72.916 48.7308 72.8058 48.3072 72.5835 47.7786L69.8088 39.8471C69.3651 38.473 68.6992 37.8385 67.812 37.8385C67.2563 37.8385 66.5913 38.0494 65.7031 38.473L55.7155 42.9149L57.0464 32.3393L57.1585 31.3871C57.1585 29.8011 56.2703 29.1666 54.3837 29.1666H45.6159C43.5079 29.1666 42.842 29.6952 42.842 31.282C42.842 31.7048 42.842 32.0225 42.9523 32.3393L44.2841 42.9149L34.1853 38.473C33.2972 38.0494 32.6322 37.8385 32.0765 37.8385C31.079 37.8385 30.5224 38.473 30.0788 39.8471L27.4152 47.7786C27.1938 48.3072 27.0827 48.7308 27.0827 49.1536C27.0827 50.4227 27.8588 50.9513 29.7463 51.269L40.6221 53.2777Z"
        fill="#974DE0"
      />
    </svg>
  );
}
