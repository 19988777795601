import React, { useState } from 'react';

import Title from '~/components/typography/Title';
import FooterEmailSubscription from '~/components/page/footer/FooterEmailSubscription';
import FooterBottom from '~/components/page/footer/FooterBottom';
import { MailingStatus } from '~/resources/useMailing';
import FooterMailingStatusText from '~/components/page/footer/FooterMailingStatusText';
import c from './Footer.sass';

import rectOrange from './images/RectOrange.svg';
import poligpnPurple from './images/PoligonPurple.svg';
import eclipseBlue from './images/EclipseBlue.svg';
import starBlue from './images/StarBlue.svg';

export default function Footer() {
  const [mailingStatus, setMailingStatus] = useState<MailingStatus>();

  return (
    <footer className={c.footer_container}>
      <img src={rectOrange} className={c.rect_orange} alt="bg" draggable="false" />
      <img src={poligpnPurple} className={c.poligon_purple} alt="bg" draggable="false" />
      <img src={eclipseBlue} className={c.eclipse_blue} alt="bg" draggable="false" />
      <img src={starBlue} className={c.star_blue} alt="bg" draggable="false" />

      <div>
        <div className={c.header_container}>
          <Title level={1}>Рассылка от&nbsp;редакции</Title>
          {!mailingStatus ? (
            <Title level={4}>
              В каждом письме — 15 полезных ссылок. Ждите&nbsp;по&nbsp;вторникам
            </Title>
          ) : <FooterMailingStatusText status={mailingStatus} />}
        </div>
        {!mailingStatus && <FooterEmailSubscription setMailingStatus={setMailingStatus} />}
      </div>

      <FooterBottom />
    </footer>
  );
}
