import React from 'react';

export default function Logo() {
  return (
    <svg width="168" height="30" viewBox="0 0 168 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.518 10.4097C42.2442 10.4097 42.5114 10.7184 42.5114 11.3737V12.2996C43.6194 10.9113 45.5301 10.0241 47.5551 10.0241C50.2677 10.0241 52.0255 11.104 53.2098 13.1479C55.0057 11.0654 57.107 10.0241 59.5524 10.0241C64.1374 10.0241 66.5446 13.0709 66.5446 19.2027V28.728C66.5446 29.2296 66.3536 29.4996 65.7804 29.4996H60.2403C59.7436 29.4996 59.4761 29.2296 59.4761 28.728V20.128C59.4761 17.5058 58.7502 16.1945 57.0307 16.1945C55.2349 16.1945 54.509 17.5058 54.509 20.128V28.728C54.509 29.2296 54.2798 29.4996 53.7831 29.4996H48.2427C47.746 29.4996 47.4785 29.2296 47.4785 28.728V20.128C47.4785 17.5058 46.7526 16.1945 45.0331 16.1945C43.2375 16.1945 42.5114 17.5058 42.5114 20.128V28.728C42.5114 29.3066 42.2822 29.4996 41.7855 29.4996H36.2071C35.7104 29.4996 35.481 29.3066 35.481 28.728V11.3737C35.481 10.7184 35.7867 10.4097 36.4363 10.4097H41.518Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0672 10.6869C75.7916 10.6869 76.0968 10.9528 76.0968 11.6746V28.5C76.0968 29.2218 75.7916 29.4877 75.0672 29.4877H70.0717C69.4234 29.4877 69.0803 29.1459 69.0803 28.5V11.6746C69.0803 10.9528 69.4234 10.6869 70.0717 10.6869H75.0672ZM76.4781 4.49602C76.4781 6.66097 74.9146 8.33218 72.5884 8.33218C70.2243 8.33218 68.699 6.69878 68.699 4.49602C68.699 2.29302 70.2243 0.583984 72.5884 0.583984C74.9146 0.583984 76.4781 2.29302 76.4781 4.49602Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.5738 10.4097C85.3025 10.4097 85.571 10.7184 85.571 11.3737V12.2996C86.6831 10.9113 88.6392 10.0241 90.6718 10.0241C95.5426 10.0241 98.0357 13.0709 98.0357 19.2027V28.728C98.0357 29.2296 97.8054 29.4996 97.3065 29.4996H91.7455C91.247 29.4996 90.9785 29.2296 90.9785 28.728V20.128C90.9785 17.5058 90.173 16.2329 88.2941 16.2329C86.4146 16.2329 85.571 17.5444 85.571 20.128V28.728C85.571 29.3066 85.3406 29.4996 84.8423 29.4996H79.2427C78.7441 29.4996 78.514 29.3066 78.514 28.728V11.3737C78.514 10.7184 78.821 10.4097 79.4727 10.4097H84.5738Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.553 17.2232C111.553 16.8092 111.439 16.696 111.02 16.696H110.602C107.862 16.696 106.225 18.0141 106.225 20.2359C106.225 22.4953 107.709 23.6628 110.602 23.6628H111.02C111.439 23.6628 111.553 23.5121 111.553 23.098V17.2232ZM110.602 10.8592H111.02C111.439 10.8592 111.553 10.7462 111.553 10.3319V2.00977C111.553 1.36932 111.895 1.06821 112.542 1.06821H117.603C118.25 1.06821 118.555 1.36932 118.555 2.00977V28.5583C118.555 29.1982 118.25 29.4996 117.603 29.4996H110.373C103.105 29.4996 99.0331 26.3363 99.0331 20.2733C99.0331 14.1352 103.485 10.8592 110.602 10.8592Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.306 17.3508H133.004C132.888 15.7062 131.88 14.622 130.213 14.622C128.701 14.622 127.655 15.5938 127.306 17.3508ZM138.586 27.3691C136.299 28.8641 133.779 29.4996 130.329 29.4996C124.011 29.4996 119.98 25.4625 119.98 19.8179C119.98 14.0986 124.205 10.0241 130.213 10.0241C136.299 10.0241 140.214 14.0611 140.214 19.6683V20.7525C140.214 21.2382 140.02 21.4627 139.516 21.4627H127.229C127.538 23.2197 129.05 24.229 131.104 24.229C132.926 24.229 134.593 23.8177 136.027 22.9952C136.376 22.8084 136.686 22.6963 136.919 22.6963C137.19 22.6963 137.423 22.8458 137.539 23.1448L139.012 25.9485C139.128 26.2102 139.206 26.397 139.206 26.5465C139.206 26.8829 139.012 27.1446 138.586 27.3691Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.093 10.4473C148.757 10.4473 149.108 10.7553 149.108 11.4094V12.7567C150.318 11.0248 151.841 10.0241 154.457 10.0241H154.73C155.472 10.0241 155.746 10.3319 155.746 11.0631V15.7975C155.746 16.4518 155.472 16.7981 154.73 16.7981H152.973C150.006 16.7981 149.108 17.8759 149.108 21.3783V28.7295C149.108 29.2301 148.874 29.4996 148.366 29.4996H142.665C142.158 29.4996 141.924 29.2301 141.924 28.7295V11.4094C141.924 10.7553 142.236 10.4473 142.9 10.4473H148.093Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.348 9.29675L159.366 7.16318L156.458 6.60807C155.953 6.52027 155.746 6.37418 155.746 6.02346C155.746 5.90663 155.775 5.78957 155.835 5.64347L156.547 3.4516C156.665 3.07186 156.814 2.8965 157.081 2.8965C157.229 2.8965 157.407 2.95479 157.645 3.07186L160.345 4.29937L159.989 1.3768C159.959 1.28924 159.959 1.20144 159.959 1.08461C159.959 0.646093 160.137 0.5 160.701 0.5H163.045C163.549 0.5 163.787 0.675359 163.787 1.11364L163.757 1.3768L163.401 4.29937L166.072 3.07186C166.309 2.95479 166.487 2.8965 166.635 2.8965C166.873 2.8965 167.051 3.07186 167.169 3.4516L167.911 5.64347C167.971 5.78957 168 5.90663 168 6.02346C168 6.37418 167.793 6.52027 167.288 6.60807L164.351 7.16318L166.398 9.29675C166.606 9.50138 166.724 9.70601 166.724 9.88137C166.724 10.0858 166.576 10.2904 166.309 10.4657L164.41 11.8101C164.202 11.9562 164.054 12.0147 163.906 12.0147C163.668 12.0147 163.52 11.8686 163.312 11.5179L161.858 8.94604L160.434 11.5179C160.226 11.8393 160.048 12.0147 159.84 12.0147C159.692 12.0147 159.514 11.9562 159.306 11.8101L157.437 10.4657C157.17 10.2611 157.021 10.0858 157.021 9.88137C157.021 9.70601 157.14 9.50138 157.348 9.29675Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10557 10.4486C6.76251 10.4486 7.11021 10.7566 7.11021 11.4107V12.758C8.3082 11.0261 9.81517 10.0254 12.4042 10.0254H12.6747C13.4089 10.0254 13.6794 10.3332 13.6794 11.0644V15.7988C13.6794 16.4531 13.4089 16.7994 12.6747 16.7994H10.9357C7.99921 16.7994 7.11021 17.8772 7.11021 21.3796V28.7309C7.11021 29.2314 6.87841 29.5009 6.37609 29.5009H0.734373C0.232049 29.5009 0 29.2314 0 28.7309V11.4107C0 10.7566 0.309236 10.4486 0.966178 10.4486H6.10557Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2316 17.2754H26.9313C26.815 15.6476 25.8067 14.5744 24.1397 14.5744C22.6274 14.5744 21.5804 15.5364 21.2316 17.2754ZM32.5705 27.3704C30.2834 28.8654 27.7639 29.5009 24.3141 29.5009C17.9958 29.5009 13.9644 25.4638 13.9644 19.8192C13.9644 14.0999 18.1895 10.0254 24.1978 10.0254C30.2834 10.0254 34.1985 14.0624 34.1985 19.6696V20.7538C34.1985 21.2395 34.0048 21.464 33.5006 21.464H21.213C21.523 23.221 23.0349 24.2303 25.0893 24.2303C26.9112 24.2303 28.5777 23.819 30.012 22.9965C30.361 22.8097 30.671 22.6976 30.9035 22.6976C31.1749 22.6976 31.4076 22.8472 31.5239 23.1461L32.9968 25.9498C33.1133 26.2115 33.1907 26.3983 33.1907 26.5478C33.1907 26.8842 32.9967 27.1459 32.5705 27.3704Z"
        fill="currentColor"
      />
    </svg>
  );
}
