import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

export interface ImageProps {
    className?: string
    id?: number
    src?: string
    alt?: string
    width?: number
    height?: number
    prefix?: string
    postfix?: string
    preview?: string
    mode?: ('cover' | 'center' | 'wide' | 'fill' | 'preview_square' | 'preview_longread' | 'only_original' | string)
    imageConfig?
    description?: string
    contentEditable?: boolean
    defRation?: number
    onClick?
    resizeByHeight?: boolean
}

const windowBreakPoints = {
  lg: null,
  md: 1024,
  sm: 768,
  xs: 320,
};

export const getImagePropsByElem = (elem: HTMLElement) => {
  const props = {
    id: 0,
    width: 0,
    height: 0,
    prefix: '',
    postfix: '',
    mode: '',
    description: '',
    src: '',
    alt: '',
  };

  if (elem.hasAttribute('data-id')) {
    props.id = +elem.getAttribute('data-id');
  }
  if (elem.hasAttribute('data-width')) {
    props.width = +elem.getAttribute('data-width');
  }
  if (elem.hasAttribute('data-height')) {
    props.height = +elem.getAttribute('data-height');
  }
  if (elem.hasAttribute('data-prefix')) {
    props.prefix = elem.getAttribute('data-prefix');
  }
  if (elem.hasAttribute('data-postfix')) {
    props.postfix = elem.getAttribute('data-postfix');
  }
  if (elem.hasAttribute('data-mode')) {
    props.mode = elem.getAttribute('data-mode');
  }
  const figcaption = elem.querySelector('figcaption');
  if (figcaption) {
    props.description = figcaption.textContent;
  }
  const img = elem.querySelector('img');
  if (img.hasAttribute('alt')) {
    props.alt = img.getAttribute('alt');
  }
  if (img.hasAttribute('src')) {
    props.src = img.getAttribute('src');
  }

  return props;
};

export const getNewImage = (props, imageConfig) => {
  const div = document.createElement('div');
  ReactDOM.render(<Image {...props} imageConfig={imageConfig} />, div);
  return div;
};

const Image: FunctionComponent<ImageProps> = ({
  className,
  id,
  src, alt,
  width = 0, height = 0,
  prefix, postfix,
  preview, mode = 'center',
  imageConfig,
  description,
  contentEditable = false,
  defRation = 1.84,
  onClick,
  resizeByHeight = false,
  children,
}) => {
  let imageRatio = +width / +height;
  if (width === 0 && height === 0) {
    imageRatio = defRation;
  }
  let placeRatio = imageRatio;
  let imageWidth = 1;

  let mediaSet = [];
  if (imageConfig && imageConfig.modes && imageConfig.modes[mode]) {
    const modeConfig = imageConfig.modes[mode];
    if (modeConfig.generator !== 'resize') {
      placeRatio = modeConfig.ratio;
    }
    if (modeConfig.generator === 'frame') {
      imageWidth = imageRatio / placeRatio;
    }
    mediaSet = Object.entries(modeConfig.sizes).reverse().map(([key]) => {
      const media = windowBreakPoints[key] ? `(max-width: ${windowBreakPoints[key]}px)` : '';
      const dpiImages = Object.values(modeConfig.resolutions).map((dpr) => `${prefix}_${mode}_${key}_${dpr}${postfix} ${dpr}`);
      return (
        <source
          key={key}
          media={media}
          srcSet={dpiImages.join(', ')}
        />
      );
    });
  }

  const styles: {[key: string]: any} = {
    paddingTop: `${100 / placeRatio}%`,
    width: `${imageWidth * 100}%`,
  };
  if (resizeByHeight) {
    styles.height = height;
  }
  return (
    <figure
      data-id={id}
      data-width={width}
      data-height={height}
      data-prefix={prefix}
      data-postfix={postfix}
      data-mode={mode}
      className={`image place-${mode}${className ? ` ${className}` : ''}`}
      contentEditable={contentEditable}
      onClick={onClick}
    >
      <picture
        style={styles}
      >
        <div
          style={{
            backgroundImage: `url('${preview || src}')`,
          }}
        />
        {mediaSet}
        <img src={src} alt={alt} loading="lazy" />
      </picture>
      {description && (
        <figcaption>{description}</figcaption>
      )}
      {children}
    </figure>
  );
};

export default Image;
