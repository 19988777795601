import React from 'react';

export default function ArrowRightBoldIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4329 10.8558L9.43142 14.8572L10.7093 16.0959L16.8389 9.96637L10.6965 3.82397L9.41861 5.06271L13.4329 9.07695H3.33301V10.8558H13.4329Z"
        fill="black"
      />
    </svg>
  );
}
