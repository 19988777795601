import React, { FunctionComponent } from 'react';

const LogoVKontakteIcon: FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.4497 5.68178C23.6174 5.12485 23.4497 4.71643 22.6568 4.71643H20.0303C19.362 4.71643 19.0566 5.06975 18.8889 5.45781C18.8889 5.45781 17.5535 8.71435 15.6611 10.8259C15.0503 11.4403 14.7712 11.6343 14.4382 11.6343C14.2718 11.6343 14.0298 11.4403 14.0298 10.8834V5.68178C14.0298 5.01346 13.837 4.71643 13.2801 4.71643H9.15281C8.73601 4.71643 8.48449 5.02544 8.48449 5.32007C8.48449 5.95245 9.43067 6.09857 9.52769 7.88075V11.7469C9.52769 12.5937 9.37558 12.7482 9.04022 12.7482C8.15033 12.7482 5.9849 9.47967 4.69977 5.73807C4.44945 5.01106 4.19673 4.71763 3.52602 4.71763H0.900669C0.149712 4.71763 0 5.07095 0 5.459C0 6.15606 0.889889 9.60543 4.14523 14.1675C6.31546 17.2827 9.37079 18.9714 12.1542 18.9714C13.8226 18.9714 14.0286 18.5965 14.0286 17.9498V15.5951C14.0286 14.8454 14.1879 14.6945 14.7161 14.6945C15.1042 14.6945 15.7725 14.8909 17.3307 16.3916C19.1105 18.1714 19.4051 18.9702 20.4052 18.9702H23.0305C23.7803 18.9702 24.1552 18.5954 23.9396 17.8552C23.7036 17.1186 22.8533 16.0466 21.725 14.7783C21.1118 14.0549 20.1956 13.2764 19.9165 12.8871C19.5273 12.3853 19.6398 12.1637 19.9165 11.7182C19.9177 11.7194 23.1168 7.21363 23.4497 5.68178Z"
      fill="#0077FF"
    />
  </svg>

);

export default LogoVKontakteIcon;
