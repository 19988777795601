import React, { FunctionComponent, useState } from 'react';
import { Page, Paginator, usePageContext } from '~/components';
import config from '~/config';
import Helmeter from '~/screens/Helmeter';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';
import PostList from '~/components/post/PostList';
import { PostType } from '~/resources/PostType';
import GuidesHeader from '~/components/guides/header/GuidesHeader';
import SubscriptionFormSection from '~/components/guides/subscriptionFormSection/SubscriptionFormSection';
import SubscriptionReviews from '~/components/PaidSubscription/ui/SubscriptionReviews';

import classes from './GuidesScreen.sass';

const SUBSCRIPTION_FORM_IDX = 3;

const GuidesScreen: FunctionComponent = () => {
  const canonicalLink = `${config('APP_HOST')}/guides`;

  const { profile } = usePageContext();
  const isProfileSubscribed = profile && profile[0] && profile[0].is_subscribed;

  const [reviewsVisible, setReviewsVisible] = useState(false);

  const handlePaginatorLoading = (loading: boolean, page: number) => {
    if (page === 1) setReviewsVisible(!loading);
  };

  return (
    <>
      <Banner />
      <Page>
        <Helmeter link={[{ rel: 'canonical', href: canonicalLink }]} />
        <JsonLd />
        <Paginator onLoading={handlePaginatorLoading}>
          {(page, handleMeta, handleLoading) => (
            <PostList
              isMainPage={false}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
              params={{ type: PostType.GUIDE, page: String(page) }}
              awaitProfile
              beforeElement={<GuidesHeader />}
              getMiddleElements={(isSubscribed) => (
                !isSubscribed
                  ? { [SUBSCRIPTION_FORM_IDX]: <SubscriptionFormSection /> }
                  : undefined
              )}
              noDividersIdx={(isSubscribed) => (
                !isSubscribed
                  ? [SUBSCRIPTION_FORM_IDX - 1, SUBSCRIPTION_FORM_IDX]
                  : undefined
              )}
            />
          )}
        </Paginator>
        {!isProfileSubscribed && reviewsVisible && (
          <SubscriptionReviews withLoader={false} className={classes.reviews_section} />
        )}
      </Page>
    </>
  );
};

export default GuidesScreen;
