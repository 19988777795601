import React, { FunctionComponent } from 'react';

const ReminderIcon: FunctionComponent = () => (
  <svg width="190" height="32" viewBox="0 0 190 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="190" height="32" fill="#F2F2F2" />
    <g id="Cert-A4-Mobile" clipPath="url(#clip0_5903_1135)">
      <rect width="595" height="842" transform="translate(-30 -51)" fill="white" />
      <g id="Group 86">
        <g id="re">
          <g id="Group">
            <path id="Vector" d="M23.6726 18.3356H29.9006C29.7732 16.5339 28.6716 15.346 26.85 15.346C25.1977 15.346 24.0537 16.4108 23.6726 18.3356ZM36.0013 29.3115C33.5018 30.9495 30.7478 31.6457 26.9771 31.6457C20.0714 31.6457 15.665 27.2229 15.665 21.0386C15.665 14.7726 20.2833 10.3086 26.8501 10.3086C33.5018 10.3086 37.7809 14.7315 37.7809 20.8747V22.0626C37.7809 22.5948 37.569 22.8407 37.0182 22.8407H23.5881C23.9266 24.7656 25.5791 25.8714 27.8244 25.8714C29.8158 25.8714 31.6376 25.4208 33.2052 24.5197C33.5863 24.3151 33.9253 24.1922 34.1795 24.1922C34.4761 24.1922 34.7303 24.3559 34.8574 24.6835L36.4674 27.7552C36.5945 28.042 36.6792 28.2465 36.6792 28.4104C36.6792 28.779 36.4674 29.0656 36.0013 29.3115Z" fill="black" />
            <path id="Vector_2" d="M6.67337 10.867C7.39141 10.867 7.77145 11.2044 7.77145 11.9211V13.3971C9.08085 11.4996 10.728 10.4033 13.5578 10.4033H13.8535C14.6559 10.4033 14.9515 10.7405 14.9515 11.5416V16.7286C14.9515 17.4454 14.6559 17.8249 13.8535 17.8249H11.9527C8.74312 17.8249 7.77145 19.0058 7.77145 22.8429V30.8968C7.77145 31.4452 7.51809 31.7404 6.96906 31.7404H0.802669C0.25363 31.7404 0 31.4452 0 30.8968V11.9211C0 11.2044 0.337993 10.867 1.05603 10.867H6.67337Z" fill="black" />
          </g>
        </g>
        <g id="reminder 1">
          <g id="Group_2">
            <path id="Vector_3" d="M167.362 10.9754C168.088 10.9754 168.471 11.3128 168.471 12.0295V13.5055C169.794 11.608 171.459 10.5117 174.319 10.5117H174.618C175.427 10.5117 175.726 10.8489 175.726 11.65V16.837C175.726 17.5538 175.427 17.9333 174.618 17.9333H172.696C169.453 17.9333 168.471 19.1142 168.471 22.9513V31.0052C168.471 31.5536 168.216 31.8488 167.661 31.8488H161.43C160.875 31.8488 160.619 31.5536 160.619 31.0052V12.0295C160.619 11.3128 160.961 10.9754 161.686 10.9754H167.362Z" fill="black" />
            <path id="Vector_4" d="M144.135 18.4508H150.363C150.236 16.6491 149.135 15.4612 147.313 15.4612C145.661 15.4612 144.517 16.526 144.135 18.4508ZM156.464 29.4267C153.965 31.0646 151.211 31.7609 147.44 31.7609C140.534 31.7609 136.128 27.338 136.128 21.1538C136.128 14.8878 140.746 10.4238 147.313 10.4238C153.965 10.4238 158.244 14.8467 158.244 20.9899V22.1778C158.244 22.71 158.032 22.9559 157.481 22.9559H144.051C144.39 24.8808 146.042 25.9865 148.287 25.9865C150.279 25.9865 152.1 25.536 153.668 24.6349C154.049 24.4302 154.388 24.3073 154.642 24.3073C154.939 24.3073 155.193 24.4711 155.32 24.7986L156.93 27.8704C157.057 28.1572 157.142 28.3617 157.142 28.5256C157.142 28.8942 156.93 29.1808 156.464 29.4267Z" fill="black" />
            <path id="Vector_5" d="M125.734 18.3986C125.734 17.9448 125.609 17.8208 125.152 17.8208H124.694C121.699 17.8208 119.911 19.265 119.911 21.6991C119.911 24.1745 121.533 25.4535 124.694 25.4535H125.152C125.609 25.4535 125.734 25.2885 125.734 24.8348V18.3986ZM124.694 11.4261H125.152C125.609 11.4261 125.734 11.3023 125.734 10.8484V1.73078C125.734 1.02912 126.108 0.699219 126.815 0.699219H132.347C133.054 0.699219 133.387 1.02912 133.387 1.73078V30.817C133.387 31.518 133.054 31.8483 132.347 31.8483H124.444C116.5 31.8483 112.05 28.3826 112.05 21.7402C112.05 15.0152 116.916 11.4261 124.694 11.4261Z" fill="black" />
            <path id="Vector_6" d="M95.0629 10.9342C95.8592 10.9342 96.1528 11.2724 96.1528 11.9904V13.0047C97.3682 11.4837 99.5063 10.5117 101.728 10.5117C107.052 10.5117 109.777 13.8498 109.777 20.5677V31.0035C109.777 31.5531 109.525 31.8488 108.98 31.8488H102.901C102.357 31.8488 102.063 31.5531 102.063 31.0035V21.5816C102.063 18.7086 101.183 17.314 99.1291 17.314C97.0749 17.314 96.1528 18.7508 96.1528 21.5816V31.0035C96.1528 31.6374 95.901 31.8488 95.3563 31.8488H89.236C88.6909 31.8488 88.4395 31.6374 88.4395 31.0035V11.9904C88.4395 11.2724 88.7749 10.9342 89.4874 10.9342H95.0629Z" fill="black" />
            <path id="Vector_7" d="M84.3348 11.2376C85.1266 11.2376 85.4602 11.5289 85.4602 12.3197V30.7534C85.4602 31.5441 85.1266 31.8355 84.3348 31.8355H78.8747C78.1661 31.8355 77.7911 31.461 77.7911 30.7534V12.3197C77.7911 11.5289 78.1661 11.2376 78.8747 11.2376H84.3348ZM85.8766 4.45491C85.8766 6.82681 84.1677 8.65778 81.6252 8.65778C79.0412 8.65778 77.374 6.86824 77.374 4.45491C77.374 2.04134 79.0412 0.168945 81.6252 0.168945C84.1677 0.168945 85.8766 2.04134 85.8766 4.45491Z" fill="black" />
            <path id="Vector_8" d="M47.327 10.9342C48.1208 10.9342 48.4128 11.2724 48.4128 11.9904V13.0047C49.6238 11.4837 51.7122 10.5117 53.9256 10.5117C56.8905 10.5117 58.8117 11.6949 60.1061 13.9342C62.069 11.6525 64.3657 10.5117 67.0386 10.5117C72.05 10.5117 74.681 13.8498 74.681 20.5677V31.0035C74.681 31.5531 74.4723 31.8488 73.8458 31.8488H67.7903C67.2475 31.8488 66.9551 31.5531 66.9551 31.0035V21.5816C66.9551 18.7086 66.1617 17.2719 64.2824 17.2719C62.3195 17.2719 61.526 18.7086 61.526 21.5816V31.0035C61.526 31.5531 61.2756 31.8488 60.7327 31.8488H54.6771C54.1341 31.8488 53.8419 31.5531 53.8419 31.0035V21.5816C53.8419 18.7086 53.0485 17.2719 51.169 17.2719C49.2064 17.2719 48.4128 18.7086 48.4128 21.5816V31.0035C48.4128 31.6374 48.1623 31.8488 47.6193 31.8488H41.5221C40.9793 31.8488 40.7285 31.6374 40.7285 31.0035V11.9904C40.7285 11.2724 41.0628 10.9342 41.7728 10.9342H47.327Z" fill="black" />
          </g>
        </g>
        <g id="star">
          <g id="Group_3">
            <path id="Vector_9" d="M178.356 9.63761L180.562 7.3001L177.385 6.69193C176.832 6.59574 176.605 6.43568 176.605 6.05144C176.605 5.92344 176.638 5.79519 176.704 5.63513L177.481 3.23373C177.611 2.81769 177.773 2.62557 178.066 2.62557C178.228 2.62557 178.422 2.68944 178.681 2.81769L181.633 4.16254L181.243 0.960607C181.211 0.864678 181.211 0.768486 181.211 0.640492C181.211 0.160058 181.405 0 182.022 0H184.584C185.135 0 185.395 0.192121 185.395 0.672295L185.361 0.960607L184.973 4.16254L187.891 2.81769C188.152 2.68944 188.346 2.62557 188.508 2.62557C188.767 2.62557 188.963 2.81769 189.091 3.23373L189.902 5.63513C189.968 5.79519 190 5.92344 190 6.05144C190 6.43568 189.772 6.59574 189.221 6.69193L186.01 7.3001L188.248 9.63761C188.476 9.8618 188.604 10.086 188.604 10.2781C188.604 10.502 188.444 10.7262 188.152 10.9183L186.076 12.3912C185.848 12.5512 185.686 12.6154 185.524 12.6154C185.265 12.6154 185.103 12.4553 184.876 12.0711L183.286 9.25337L181.73 12.0711C181.503 12.4232 181.309 12.6154 181.081 12.6154C180.918 12.6154 180.724 12.5512 180.498 12.3912L178.454 10.9183C178.162 10.6941 178 10.502 178 10.2781C178 10.086 178.13 9.8618 178.356 9.63761Z" fill="black" />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5903_1135">
        <rect width="595" height="842" fill="white" transform="translate(-30 -51)" />
      </clipPath>
    </defs>
  </svg>
);

export default ReminderIcon;
