import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import {
  usePageContext, Page, Loader, AlertModalType,
} from '~/components';
import Banner from '~/components/Banner';
import anyFetch from '~/resources/anyFetch';
import config from '~/config';
import useProfile from '~/resources/useProfile';
import disableScroll from '~/utils/disableScroll';

const VerifyScreen: FunctionComponent = () => {
  const { key } = useParams<{ key: string }>();
  const history = useHistory();
  const { auth, alertModal, profile: profileState } = usePageContext();
  const { data: profile, fetchBy: fetchProfile } = useProfile();

  useEffect(() => {
    window.scroll({ top: 0 });
  });
  useEffect(() => {
    const fetchData = async () => {
      const url = `${config('API_HOST')}/oauth/token`.replace('/api', '');
      const res = await anyFetch(url, {
        method: 'POST',
        body: JSON.stringify({
          key,
          grant_type: 'verify_email',
          client_id: config('CLIENT_ID'),
          client_secret: config('CLIENT_SECRET'),
        }),
      });
      const resData = await res.json();
      if (resData.access_token && auth) {
        auth.setAuth(resData);
        fetchProfile();
      } else if (alertModal) {
        const alertType = res.status === 403 ? AlertModalType.FAIL : AlertModalType.ERROR;
        alertModal[1]({ alertType });
        disableScroll(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!profile || !profileState) { return; }
    profileState[1](profile);
    history.push('/profile');
  }, [profile]);

  return (
    <>
      <Banner />
      <Page>
        <Loader />
      </Page>
    </>
  );
};

export default VerifyScreen;
