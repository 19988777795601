import React from 'react';

export default function SearchIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5878 21.2272L13.246 14.8853C12.75 15.2694 12.1779 15.5741 11.5299 15.7994C10.8819 16.0248 10.1893 16.1375 9.45195 16.1375C7.59942 16.1375 6.03004 15.4944 4.7438 14.2081C3.45757 12.9219 2.81445 11.3525 2.81445 9.49999C2.81445 7.64745 3.45757 6.07807 4.7438 4.79184C6.03004 3.5056 7.59942 2.86249 9.45195 2.86249C11.3045 2.86249 12.8739 3.5056 14.1601 4.79184C15.4463 6.07807 16.0895 7.64745 16.0895 9.49999C16.0895 10.2413 15.9768 10.934 15.7514 11.578C15.5261 12.222 15.2213 12.79 14.8373 13.2821L21.1851 19.6359L19.5878 21.2272ZM9.45195 13.8625C10.6661 13.8625 11.6967 13.4389 12.5438 12.5918C13.3909 11.7447 13.8145 10.7141 13.8145 9.49999C13.8145 8.28585 13.3909 7.25524 12.5438 6.40814C11.6967 5.56104 10.6661 5.13749 9.45195 5.13749C8.23782 5.13749 7.2072 5.56104 6.3601 6.40814C5.513 7.25524 5.08945 8.28585 5.08945 9.49999C5.08945 10.7141 5.513 11.7447 6.3601 12.5918C7.2072 13.4389 8.23782 13.8625 9.45195 13.8625Z"
        fill="currentColor"
      />
    </svg>
  );
}
