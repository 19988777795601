import React, {
  FC, FormEvent, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router';
import config from '~/config';
import anyFetch from '~/resources/anyFetch';
import validateEmail from '~/utils/email';
import c from './DonateForm.sass';
import DonateUnsubscribeModal from './DonateUnsubscribeModal';

const keys = {
  LOADING: 0,
  LETTER_SENT: 1,
  SUCCESS: 2,
  NOT_FOUND: 3,
  ALREADY_UNSUBSCRIBED: 4,
  SOMETHING_WENT_WRONG: 5,
};
const states = {
  [keys.LOADING]: { key: keys.LOADING },
  [keys.LETTER_SENT]: { key: keys.LETTER_SENT },
  [keys.SUCCESS]: { key: keys.SUCCESS },
  [keys.NOT_FOUND]: { key: keys.NOT_FOUND },
  [keys.ALREADY_UNSUBSCRIBED]: { key: keys.ALREADY_UNSUBSCRIBED },
  [keys.SOMETHING_WENT_WRONG]: { key: keys.SOMETHING_WENT_WRONG },
};
const DONATE_URL = `${config('API_HOST')}/donates/unsubscribe`;
const SUBSCRIPTION_URL = `${config('API_HOST')}/subscription/unsubscribe`;

const DonateUnsubscribeForm: FC = () => {
  const { pathname } = useLocation();
  const [email, setEmail] = useState('');
  const [state, setState] = useState(states[keys.LOADING]);
  const [openModal, setOpenModal] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const URL = pathname.match(/donate/) !== null ? DONATE_URL : SUBSCRIPTION_URL;

  const sendFormData = async (_email: string) => {
    const res = await anyFetch(URL, {
      method: 'POST',
      body: JSON.stringify({ email: _email }),
    });
    if (!res.ok) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
      throw new Error(`Donate unsubscribe letter fetch error. Status: ${res.status}`);
    }
    const json = await res.json();
    if (json.error) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
      throw new Error(`Donate unsubscribe letter fetch data error: ${json.error}`);
    }
    switch (json.data.status) {
      case 'sent': {
        setState(states[keys.LETTER_SENT]);
        break;
      }
      case 'not_found': {
        setState(states[keys.NOT_FOUND]);
        break;
      }
      default: {
        setState(states[keys.SOMETHING_WENT_WRONG]);
        break;
      }
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState(states[keys.LOADING]);
    if (!validateEmail(email)) {
      setIsEmailInvalid(true);
      throw new Error('Email is invalid');
    }
    setOpenModal(true);
    await sendFormData(email);
  };

  useEffect(() => {
    (async () => {
      if (typeof window === 'undefined') return;
      const urlParams = new URLSearchParams(window.location.search);
      const key = urlParams.get('key');
      if (!key) return;
      window.history.pushState(
        {},
        document.title,
        document.location.origin + document.location.pathname,
      );
      setOpenModal(true);
      setState(states[keys.LOADING]);
      const res = await anyFetch(`${URL}/${key}`);
      if (!res.ok) {
        setState(states[keys.SOMETHING_WENT_WRONG]);
        throw new Error(`Donate unsubscribe fetch hook error. Status: ${res.status}`);
      }
      const json = await res.json();
      if (json.error) {
        setState(states[keys.SOMETHING_WENT_WRONG]);
        throw new Error(`Donate unsubscribe fetch hook data error: ${json.error}`);
      }
      switch (json.data.status) {
        case 'ok': {
          setState(states[keys.SUCCESS]);
          break;
        }
        case 'already_unsubscribed': {
          setState(states[keys.ALREADY_UNSUBSCRIBED]);
          break;
        }
        default: {
          setState(states[keys.SOMETHING_WENT_WRONG]);
          break;
        }
      }
    })();
  }, []);

  return (
    <>
      <DonateUnsubscribeModal
        keys={keys}
        stateKey={state.key}
        open={openModal}
        setOpenModal={setOpenModal}
      />
      <form
        className={c.form}
        onSubmit={onSubmit}
        style={{ gap: 0 }}
      >
        <div className={c.boxEmail}>
          <input
            name="email"
            placeholder="Введите e-mail"
            aria-label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={isEmailInvalid ? `${c.inputText} ${c.inputText_invalid}` : c.inputText}
            onInput={() => setIsEmailInvalid(false)}
            required
          />
          {isEmailInvalid && <span className={c.error}>Неправильно ввели адрес почты</span>}
        </div>
        <button
          type="submit"
          aria-label="Войти"
          className={`${c.inputText} ${c.submit}`}
          style={{ transform: 'translateY(45px)' }}
        >
          Войти
        </button>
      </form>
    </>
  );
};

export default DonateUnsubscribeForm;
