import React, { FunctionComponent } from 'react';

import c from './Loader.sass';

interface Props {
    id?: string
    className?: string
}

const Loader: FunctionComponent<Props> = ({
  id, className,
}) => (
  <div id={id} className={c.loader + (className ? ` ${className}` : '')}>
    <svg width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        r="32"
        strokeWidth="8"
        stroke="#b688e4"
        strokeDasharray="50.26548245743669 50.26548245743669"
        fill="none"
        strokeLinecap="round"
        transform="rotate(345.965 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1.4285714285714284s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;360 50 50"
        />
      </circle>
      <circle
        cx="50"
        cy="50"
        r="23"
        strokeWidth="8"
        stroke="#f85b2e"
        strokeDasharray="36.12831551628262 36.12831551628262"
        strokeDashoffset="36.12831551628262"
        fill="none"
        strokeLinecap="round"
        transform="rotate(-345.965 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1.4285714285714284s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;-360 50 50"
        />
      </circle>
    </svg>
  </div>
);

Loader.displayName = 'Loader';

export default Loader;
