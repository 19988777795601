import React, { FunctionComponent, useEffect } from 'react';
import Modal from 'react-modal';
import { checkSubscriptionStatus } from '~/resources/useSubscriptionList';
import { SubscriptionStatus } from '~/utils/purshase-event';
import disableScroll from '~/utils/disableScroll';
import c from './PaidSubscriptionForm.sass';
import Loader from '../Loader';

export interface SubscriptionCheckProps {
  orderId: number;
  onClose: () => void;
  onCheck: (status: SubscriptionStatus) => void;
}

export const SubscriptionCheck: FunctionComponent<SubscriptionCheckProps> = ({
  orderId, onClose, onCheck,
}) => {
  disableScroll(true);
  const onHandleClose = () => {
    disableScroll(false);
    onClose();
  };
  const onHandleCheck = (status: SubscriptionStatus) => {
    disableScroll(false);
    onCheck(status);
  };

  useEffect(() => {
    if (!orderId) {
      return;
    }
    const check = async () => {
      const response = await checkSubscriptionStatus(orderId);
      if (response.error) {
        onHandleCheck(SubscriptionStatus.SOMETHING_WENT_WRONG);
      } else {
        onHandleCheck(response.data.status as SubscriptionStatus);
      }
    };
    setTimeout(check, 5000);
  }, [orderId]);

  if (!orderId) {
    return null;
  }

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      onRequestClose={onHandleClose}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      <div className={c.payment_form_wrapper}>
        <Loader />
      </div>
    </Modal>
  );
};
