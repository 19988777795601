import React from 'react';

export default function ArrowLeftBoldIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5686 14.8572L9.29069 16.0959L3.16113 9.96637L9.30353 3.82397L10.5814 5.06271L6.56717 9.07695H16.667V10.8558H6.56717L10.5686 14.8572Z"
        fill="black"
      />
    </svg>
  );
}
