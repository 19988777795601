import React, { FunctionComponent } from 'react';

const MailIcon: FunctionComponent = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91 29C91 39.4934 82.4934 48 72 48C61.5066 48 53 39.4934 53 29C53 18.5066 61.5066 10 72 10C82.4934 10 91 18.5066 91 29Z"
      fill="url(#paint0_linear_6093_1556)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.5 19H78.5C80.7091 19 82.5 20.7909 82.5 23V59C82.5 61.2091 80.7091 63 78.5 63C76.2909 63 74.5 61.2091 74.5 59V32.6569L62.8284 44.3284C61.2663 45.8905 58.7337 45.8905 57.1716 44.3284C55.6095 42.7663 55.6095 40.2337 57.1716 38.6716L68.8431 27H42.5C40.2909 27 38.5 25.2091 38.5 23C38.5 20.7909 40.2909 19 42.5 19Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8035 28.7215C20.6122 27.6288 22.7327 27 25 27H39.0769C41.2861 27 43.0769 25.2091 43.0769 23C43.0769 20.7909 41.2861 19 39.0769 19H25C13.9543 19 5 27.9543 5 39V67C5 78.0457 13.9543 87 25 87H62C73.0457 87 82 78.0457 82 67V61.5443C82 59.3352 80.2091 57.5443 78 57.5443C75.7909 57.5443 74 59.3352 74 61.5443V67C74 73.6274 68.6274 79 62 79H25C18.3726 79 13 73.6274 13 67V39C13 37.961 13.132 36.9529 13.3803 35.9915C15.5178 40.1796 18.3096 44.5217 21.8814 48.1646C26.7926 53.1736 33.3367 57 41.6602 57C44.4876 57 47.1665 56.3416 49.9856 55.2139C52.0368 54.3934 53.0344 52.0656 52.214 50.0144C51.3935 47.9633 49.0656 46.9656 47.0145 47.7861C44.8336 48.6584 43.1848 49 41.6602 49C36.0329 49 31.4322 46.4787 27.5937 42.5638C23.7782 38.6724 20.8778 33.5481 18.8035 28.7215Z"
      fill="url(#paint1_linear_6093_1556)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6093_1556"
        x1="91"
        y1="32.4262"
        x2="52.9953"
        y2="32.3679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F85B2E" />
        <stop offset="1" stopColor="#B688E4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6093_1556"
        x1="82"
        y1="59.1311"
        x2="4.99056"
        y2="58.9974"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F85B2E" />
        <stop offset="1" stopColor="#B688E4" />
      </linearGradient>
    </defs>
  </svg>

);

export default MailIcon;
