import React, { FunctionComponent } from 'react';

import c from './HeaderLongread.sass';

interface Props {
  show: boolean
}

const HeaderLongread: FunctionComponent<Props> = ({ show }) => (show ? (
  <div className={c.headerLongread}>
    headerLongread
  </div>
) : null);

export default HeaderLongread;
