import React, { FunctionComponent, useEffect } from 'react';
import { GiftPage, Page } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';

const GiftScreen: FunctionComponent = () => {
  useEffect(() => {
    window.scroll({ top: 0 });
  });

  const ogImage = `${config('APP_HOST')}/public/subscription/ogGift.jpg`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="Подарочная подписка"
          metaTitle="Подарочная подписка"
          metaDescription="Подписка на лучшее знание о здоровье и саморазвитии"
          img={ogImage}
          srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
        />
        <GiftPage />
      </Page>
    </>
  );
};

export default GiftScreen;
