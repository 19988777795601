import React, { FunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useConfig } from '~/resources';
import { Post } from '~/resources/models';
import { PostType } from '~/resources/PostType';
import Topic from '../Topic';
import Image from '../Image';
import c from './PostSearch.sass';

interface Props {
  post: Post
}

const PostSearch: FunctionComponent<Props> = ({
  post: {
    topic, published_at, title, image_preview, slug, type,
  },
}) => {
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const history = useHistory();

  if (type !== PostType.LONGREAD) {
    type = PostType.POST;
  }

  const urlPost = `/${type}/${slug}`;
  const urlTopic = topic && `/topic/${topic.slug}`;
  const onClickImage = () => history.push(urlPost);

  return (
    <div className={c.post}>
      <div className={c.content}>
        <div className={c.top}>
          <Topic
            title={topic.title}
            subtitle={new Date(+published_at * 1000).toLocaleDateString('ru')}
            href={urlTopic}
          />
        </div>
        <Link to={urlPost} className={c.title}>{title}</Link>
      </div>
      <div className={c.imageWrap}>
        {image_preview && (
        <Image
          {...image_preview}
          mode="preview"
          className={c.image}
          imageConfig={imageConfig}
          contentEditable={undefined}
          onClick={onClickImage}
          alt={image_preview.alt || title}
        />
        )}
      </div>
    </div>
  );
};

export default PostSearch;
