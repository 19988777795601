import React, { FunctionComponent } from 'react';

import c from './LogoPage.sass';

const LogoPage: FunctionComponent = () => (
  <div className={c.logo}>
    <svg viewBox="0 0 1126 188" fill="black" xmlns="http://www.w3.org/2000/svg">
      <g name="end">
        <g name="star">
          <use xlinkHref="#logoStar" />
        </g>
        <use xlinkHref="#logoEnd" />
      </g>
      <g name="start">
        <use xlinkHref="#logoStart" />
      </g>
    </svg>
  </div>
);

export default LogoPage;
