import React, { FunctionComponent } from 'react';

const MailBenefitIcon: FunctionComponent = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/Mail" clipPath="url(#clip0_6134_3306)">
      <path id="Vector 14" d="M29.031 8.21295L29.5803 26.5418L-1.58212 40.6316L-5.60708 4.63439L29.031 8.21295Z" fill="#F85B2E" />
      <path id="Vector 15 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M-6.67494 9.89871L11.3814 25.2713L26.7574 13.4493L24.9288 11.071L11.4776 21.4132L-4.73018 7.61443L-6.67494 9.89871Z" fill="#25D3FF" />
    </g>
    <defs>
      <clipPath id="clip0_6134_3306">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MailBenefitIcon;
