import React from 'react';

export default function ItSummaLogoIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 112 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8493_2564)">
        <g opacity="0.6">
          <path
            d="M19.4912 13.6332C18.2122 12.0915 17.0235 10.6587 15.7386 9.10796H15.7416C18.2303 8.02274 20.6227 6.97985 23.0602 5.91881C22.6769 4.97292 22.3166 4.07854 21.9321 3.12394L21.8354 2.88383C20.5968 3.41443 19.3721 3.93964 18.1445 4.46606C16.9382 4.98333 15.7292 5.50177 14.5018 6.02764C14.3844 3.95998 14.2761 2.05556 14.1587 0C11.4988 2.77227 8.88391 5.49826 6.26298 8.23053C4.54282 10.0238 2.82006 11.8197 1.08029 13.6332H19.4912Z"
            fill="white"
          />
          <path
            d="M13.6712 31.3413C13.8999 30.1291 14.1046 29.0379 14.3062 27.9617V27.9647C16.0997 27.2181 17.8241 26.5017 19.5063 25.8034V15.1779H15.7085V22.6656C14.9931 22.8835 14.2855 23.0983 13.5698 23.3155C12.8855 23.5232 12.1938 23.7332 11.4805 23.9503C11.4985 23.5936 11.5106 23.3548 11.5196 23.1372C11.8957 22.9195 12.2147 22.7351 12.5277 22.5568V16.3659C11.7919 16.5534 11.0721 16.7362 10.3507 16.9195C9.64114 17.0997 8.92999 17.2804 8.20033 17.4662V23.8808C7.15318 23.4991 6.21507 23.1595 5.25125 22.8106L5.14289 22.7714V15.1568H1.03219V25.6673C1.91377 26.0949 2.79347 26.5206 3.66709 26.9433C4.35609 27.2767 5.04131 27.6083 5.72067 27.9375V37.7498H0V41.994H10.1564V36.3442H15.4407V42H19.5063V31.3413H13.6712Z"
            fill="white"
          />
        </g>
        <path d="M31.3897 21.3325V23.1463H34.2756V21.3325H31.3897Z" fill="white" />
        <path
          d="M42.488 32.2663H39.4275L39.4305 32.2633V26.8372H42.4248V24.168H39.3553V22.3059H36.4182V34.872H42.488V32.2663Z"
          fill="white"
        />
        <path
          d="M47.3992 26.7767H54.5012V24.2405H44.4381V30.6702H51.5491V32.2844H44.4411V34.8569H54.5192V28.0252H47.3421C47.3498 27.8555 47.3571 27.6997 47.3642 27.5493L47.3643 27.5462L47.3644 27.5448C47.3765 27.287 47.3879 27.045 47.3992 26.7767Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.876 28.0554H108.969V26.8765H101.867V24.2496H112.003V34.9234H101.876V28.0554ZM104.85 32.2028H108.957V30.6853H104.85V32.2028Z"
          fill="white"
        />
        <path
          d="M85.4186 34.872H88.3256V26.9128H91.1423V34.8599H94.1004V26.9128H96.896V34.866H99.8121V24.2556H85.4186V34.872Z"
          fill="white"
        />
        <path
          d="M77.6577 26.8916H80.4834V34.8751H83.3543V24.2617H68.9157V34.866H71.8467V26.9097H74.6424V34.863H77.6577V26.8916Z"
          fill="white"
        />
        <path d="M56.7583 34.8539V24.2436H59.6021V32.2119H63.8301V24.2677H66.8334V34.8539H56.7583Z" fill="white" />
        <path d="M31.3809 24.2526H34.2788V34.8539H31.3809V24.2526Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_8493_2564">
          <rect width="112" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
