import React from 'react';
import { FetchStatus } from '~/resources';
import disableScroll from '~/utils/disableScroll';
import Paragraph from '~/components/typography/Paragraph';
import ArrowRightFilled from '~/components/icons/ArrowRightFilled';
import { AuthModalFrom, AuthModalState, usePageContext } from '~/components';

import Title from '~/components/typography/Title';
import c from './PageMain.sass';

type TProps = {
  inHeader?: boolean;
}

export default function LoginPage(props: TProps) {
  const { inHeader } = props;

  const {
    profile,
    profileStatus,
    authModal,
  } = usePageContext();

  const onLogin = () => {
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.CHECK, authModalFrom: AuthModalFrom.NONE });
    }
    disableScroll(true);
  };

  const buttonInnerContent = [<ArrowRightFilled />];

  if (inHeader) {
    buttonInnerContent.push(<Title level={4}>Войти</Title>);
  } else {
    buttonInnerContent.push(<Paragraph weight="bold" className={c.login_label}>Войти</Paragraph>);
  }

  const buttonInnerContentReversed = [...buttonInnerContent].reverse();

  const status = profileStatus?.[0] ?? undefined;
  const isLoading = !status || status === FetchStatus.Fetching;
  const isProfile = profile && profile[0];

  const renderLogin = () => (
    <>
      <button
        className={c.login}
        type="button"
        onClick={onLogin}
      >
        {inHeader ? buttonInnerContent : buttonInnerContentReversed}
      </button>
    </>
  );

  if (isLoading || isProfile) {
    return null;
  }

  return renderLogin();
}
