import config from '~/config';
import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import { Subscription } from './models';
import anyFetch from './anyFetch';

const PATH = () => `${config('API_HOST')}/subscriptions/`;

interface SubscriptionRequest {
  subscription_plan_id: number;
  email?: string;
  discount_promocode: string | undefined;
  post_slug: string | undefined;
}

export interface SubscriptionResponse {
  data: {
    id: number,
    email: string,
    status: string,
  },
  confirmation: {
    type: string;
    confirmation_url?: string;
    confirmation_token?: string;
  }
  error?: any;
  status?: string;
}

export interface SubscriptionCheckResponse {
  data: {
    status: string,
  },
  error?: any;
}

export const makeSubscriptionPayment = async (
  planId: number,
  code: string,
  email: string | undefined,
  postSlug: string | undefined,
): Promise<SubscriptionResponse> => {
  const token = localStorage.getItem('access_token');
  const body: SubscriptionRequest = {
    subscription_plan_id: planId,
    discount_promocode: code,
    post_slug: postSlug,
  };
  if (email) {
    body.email = email;
  }
  return fetch(`${config('API_HOST')}/subscriptions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json());
};

export const checkSubscriptionStatus = async (
  orderId: number,
): Promise<SubscriptionCheckResponse> => {
  const token = localStorage.getItem('access_token');
  return fetch(`${config('API_HOST')}/subscriptions/${orderId}/status`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json());
};

export const subscriptionFetcher = async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<Subscription[]>> => {
  const path = PATH();
  const res = await anyFetch(path, opts);
  const data = await res.json();
  return data;
};
const generateCacheKey = (params: QueryParams = {}) => `subscription${JSON.stringify(params)}`;

const useSubscriptionList = (
  params: QueryParams = {},
  opts: RequestInit = {},
): ResourceResult<Subscription[]> =>
  useResource(params, opts, subscriptionFetcher, generateCacheKey(params));

export default useSubscriptionList;
