import React, { FunctionComponent, useEffect } from 'react';
import useRecommends from '~/resources/useRecommends';
import PostRecommend from './PostRecommend';

import c from './Recommendations.sass';

interface Props {
    postExcludeId?: number;
}

const Recommend: FunctionComponent<Props> = ({ postExcludeId }) => {
  const { data: posts, fetchBy } = useRecommends({ post_id: String(postExcludeId) });
  useEffect(() => {
    fetchBy();
  }, [postExcludeId]);

  if (!posts) {
    return null;
  }

  return (
    <div className={c.recommend}>
      <div className={c.title}>Продолжайте читать</div>
      <div className={c.wrap}>
        {posts.map((post) =>
          (<PostRecommend key={post.id} post={post} />))}
      </div>
    </div>
  );
};

export default Recommend;
