import React, { FunctionComponent } from 'react';

const LogoGoogleIcon: FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5910_2348)">
      <path
        d="M19.0187 11.9999C19.0198 13.6516 18.438 15.2507 17.3757 16.5155C16.3134 17.7803 14.8388 18.6296 13.2117 18.9138C11.5847 19.1981 9.90949 18.8989 8.48132 18.0692C7.05316 17.2394 5.96363 15.9323 5.4047 14.378L1.39014 17.5896C2.63844 19.9626 4.64583 21.8488 7.09178 22.9472C9.53773 24.0456 12.2812 24.2928 14.8841 23.6493C17.4869 23.0058 19.7992 21.5087 21.4515 19.3971C23.1038 17.2855 24.001 14.6811 23.9998 11.9999"
        fill="#00AC47"
      />
      <path
        d="M19.019 11.9999C19.0181 13.1165 18.7504 14.2168 18.2382 15.2091C17.7259 16.2013 16.984 17.0568 16.0742 17.7043L20.0431 20.8794C21.2877 19.7589 22.283 18.3895 22.9646 16.8599C23.6463 15.3303 23.9991 13.6745 24.0001 11.9999"
        fill="#4285F4"
      />
      <path
        d="M4.98108 11.9999C4.98281 11.1885 5.12615 10.3837 5.40465 9.62171L1.39008 6.4101C0.477262 8.1319 0 10.0511 0 11.9999C0 13.9487 0.477262 15.8678 1.39008 17.5896L5.40465 14.378C5.12615 13.616 4.98281 12.8112 4.98108 11.9999Z"
        fill="#FFBA00"
      />
      <path
        d="M11.9999 4.98108C13.4851 4.9815 14.9313 5.4561 16.1279 6.33576L19.8048 2.90398C18.4399 1.72714 16.8268 0.873744 15.0859 0.407482C13.3451 -0.0587808 11.5214 -0.125873 9.75099 0.211212C7.98058 0.548296 6.30915 1.28085 4.86144 2.35422C3.41372 3.42758 2.22711 4.81403 1.39014 6.41011L5.4047 9.62173C5.89431 8.26449 6.79 7.09096 7.97001 6.26066C9.15001 5.43037 10.5571 4.9836 11.9999 4.98108Z"
        fill="#EA4435"
      />
      <path
        d="M23.9997 11.0942V11.9998L21.962 15.1696H12.4526V10.1885H23.094C23.3342 10.1885 23.5646 10.284 23.7344 10.4538C23.9043 10.6236 23.9997 10.854 23.9997 11.0942Z"
        fill="#4285F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_5910_2348">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoGoogleIcon;
