import React, { FunctionComponent } from 'react';

import PageHeader from '~/components/page/header/PageHeader';
import MenuPage from '~/components/page/MenuPage';
import Footer from '~/components/page/footer/Footer';
import clsx from 'clsx';
import c from './PageMain.sass';
import AlertModal from '../Alert/AlertModal';
import { AuthModal } from '../Auth';

interface Props {
    dark?: boolean;
    hideSeparator?: boolean
}

const Page: FunctionComponent<Props> = ({
  dark = false,
  hideSeparator = false,
  children,
}) => (
  <>
    <div className={clsx(c.page, { [c.dark]: dark })}>
      <MenuPage />
      <div className={`${c.content} ${c.page_content} ${!hideSeparator ? c.page_content_with_separator : ''}`}>
        <PageHeader dark={dark} />
        <div className={c.inner_content}>
          {children}
        </div>
        <Footer />
      </div>

      <AuthModal />
      <AlertModal />
    </div>
  </>
);

export default Page;
