import React, { FunctionComponent } from 'react';

import c from './PreviewSoc.sass';

const PreviewSoc: FunctionComponent = () => (
  <div className={c.socPreview}>
    <div className={c.wrap}>
      <span className={c.title}>Где нас искать</span>
      <a
        className={`${c.item} ${c.fb}`}
        rel="noreferrer"
        target="_blank"
        href="https://zen.yandex.ru/reminder"
      >
        <svg width="700" height="189" viewBox="0 0 700 189">
          <path d="M350 189C543.3 189 700 146.691 700 94.5C700 42.3091 543.3 0 350 0C156.7 0 0 42.3091 0 94.5C0 146.691 156.7 189 350 189Z" fill="#CC99FF" />
        </svg>
        <span>Дзен</span>
      </a>
      <a
        className={`${c.item} ${c.yt}`}
        rel="noreferrer"
        target="_blank"
        href="https://www.youtube.com/channel/UCnVyrQeAkRRfCxhfoF3sQAA"
      >
        <svg width="175" height="173" viewBox="0 0 175 173">
          <path d="M87.5 173C135.825 173 175 134.273 175 86.5C175 38.7274 135.825 0 87.5 0C39.1751 0 0 38.7274 0 86.5C0 134.273 39.1751 173 87.5 173Z" fill="#25D3FF" />
        </svg>
        <span>YouTube</span>
      </a>
      <a
        className={`${c.item} ${c.tg}`}
        rel="noreferrer"
        target="_blank"
        href="https://t.me/justakindreminder"
      >
        <svg width="446" height="232" viewBox="0 0 446 232">
          <path d="M364.648 0L446 232H0L364.648 0Z" fill="#F85B2E" />
        </svg>
        <span>Telegram</span>
      </a>
    </div>
  </div>
);

export default PreviewSoc;
