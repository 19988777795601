import React, {
  FunctionComponent, createContext, useContext, useState, useEffect,
} from 'react';

import useProfile from '~/resources/useProfile';
import { Profile } from '~/resources/models';
import Auth from '~/resources/Auth';
import { FetchStatus } from '~/resources';
import disableScroll from '~/utils/disableScroll';
import { SubscriptionFlowState, useSubscriptionFlow } from './context.subscription-flow';

export enum AuthModalState {
  NONE = 'none',
  CHECK = 'check',
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  ALERT = 'alert',
  LOADING = 'loading',
  CHANGE_PASSWORD = 'change_password',
}

export enum AuthModalFrom {
  NONE = 'none',
  PLANS = 'plans',
  PAYWALL = 'paywall',
}

export enum AlertModalType {
  NONE = 'none',
  CONFIRM = 'confirm',
  WITHOUT_PASSWORD = 'without_password',
  FAIL = 'fail',
  ERROR = 'error',
  GIFT_SUCCESS = 'gift_success',
  GIFT_SUCCESS_DATE = 'gift_success_date',
  PAYMENT_FAIL = 'payment_fail',
  SUBSCRIPTION_GIFT_SUCCESS = 'subscription_gift_success',
  SUBSCRIPTION_GIFT_FAIL = 'subscription_gift_fail',
  PASSWORD_CHANGE_EMAIL = 'password_change_email',
  PASSWORD_CHANGED_SUCCESS = 'password_changed_succes',
  PASSWORD_CHANGE_FAIL = 'password_change_fail'
}

interface AuthModal {
  authModalState: AuthModalState,
  authModalFrom?: AuthModalFrom,
  urlKey?: string,
}

interface AlertModal {
  alertType: AlertModalType,
  email?: string,
  date?: Date | undefined,
}

interface GuideContents {
  contents: string[];
  activeIdx: number;
  shouldScrollToIdx: number | null;
  slug: string;
}

interface PropsPageContext {
  auth: Auth | undefined;
  profile: [
    Profile | undefined,
    React.Dispatch<React.SetStateAction<Profile | undefined>>,
  ] | undefined;
  profileStatus: [
    FetchStatus | undefined,
    React.Dispatch<React.SetStateAction<FetchStatus | undefined>>,
  ] | undefined,
  hideMenu: [boolean, React.Dispatch<React.SetStateAction<boolean>>] | undefined;
  lastPost: [any, React.Dispatch<React.SetStateAction<any>>] | undefined;
  subscriptionFlow: SubscriptionFlowState;
  authModal: [
    AuthModal,
    React.Dispatch<React.SetStateAction<AuthModal>>
  ] | undefined;
  alertModal: [
    AlertModal,
    React.Dispatch<React.SetStateAction<AlertModal>>
  ] | undefined;
  guideContents?: [
    GuideContents | null,
    React.Dispatch<React.SetStateAction<GuideContents | null>>
  ];
}

const PageContext = createContext<PropsPageContext>({
  auth: undefined,
  profile: undefined,
  profileStatus: undefined,
  hideMenu: undefined,
  lastPost: undefined,
  subscriptionFlow: undefined,
  authModal: undefined,
  alertModal: undefined,
});

export const usePageContext = (): PropsPageContext => useContext<PropsPageContext>(PageContext);

interface Props {
  auth: Auth;
}

const InitPage: FunctionComponent = () => {
  const { profile: profileState, profileStatus } = usePageContext();
  // const { fetchBy: fetchConfig } = useConfig();
  const { data: profile, fetchBy: fetchProfile, status } = useProfile();

  useEffect(() => {
    // fetchConfig();
    fetchProfile();
  }, []);
  useEffect(() => {
    if (profileState) {
      profileState[1](profile);
    }
  }, [profile]);
  useEffect(() => {
    if (profileStatus) {
      profileStatus[1](status);
    }
  }, [status]);
  return null;
};

export const ProviderPage: FunctionComponent<Props> = ({
  auth,
  children,
}) => {
  // const { data: profile, fetchBy } = useProfile(auth);
  const hideMenu = useState<boolean>(true);
  const subscriptionFlow = useSubscriptionFlow();
  const profile = useState<Profile | undefined>(undefined);
  const profileStatus = useState<FetchStatus | undefined>(undefined);
  const lastPost = useState<any>(undefined);
  const authModal = useState<AuthModal>({
    authModalState: AuthModalState.NONE,
    authModalFrom: AuthModalFrom.NONE,
    urlKey: undefined,
  });
  const alertModal = useState<AlertModal>({
    alertType: AlertModalType.NONE,
    email: '',
    date: undefined,
  });

  const guideContents = useState<GuideContents | null>(null);

  useEffect(() => {
    disableScroll(!hideMenu[0]);
  }, [hideMenu[0]]);

  return (
    <PageContext.Provider value={{
      hideMenu,
      profile,
      profileStatus,
      auth,
      lastPost,
      subscriptionFlow,
      authModal,
      alertModal,
      guideContents,
    }}
    >
      <InitPage />
      {children}
    </PageContext.Provider>
  );
};
