import c from '~/components/page/PageMain.sass';
import Paragraph from '~/components/typography/Paragraph';
import cProfile from '~/components/page/ProfilePage.sass';
import React from 'react';
import { usePageContext } from '~/components';
import Badge from '~/components/Badge/Badge';
import StarIcon from '~/components/icons/Star';

export default function ProfilePage() {
  const { profile } = usePageContext();

  const isProfile = profile && profile[0];
  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;

  const userName = (): string => {
    if (profile?.[0]) {
      if (profile[0].last_name === null) {
        return profile[0].name;
      }
      return `${profile[0].name} ${profile[0].last_name}`;
    }
    return '';
  };

  const renderProfile = () => (
    <>
      <div>
        <a href="/profile" className={c.login}>
          <Paragraph weight="bold" className={c.login_username}>Профиль</Paragraph>
          <Badge primary={isSubscribed}>
            <Paragraph variant="caption" size="small" className={c.badge_text}>
              {isSubscribed ? (
                <div className={cProfile.reminder_badge_content}>
                  <StarIcon />
                  REMINDER
                </div>
              ) : 'FREE'}
            </Paragraph>
          </Badge>
        </a>
        <Paragraph variant="caption" className={cProfile.profile_username}>{userName()}</Paragraph>
      </div>
    </>
  );

  if (isProfile) return renderProfile();

  return null;
}
