import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { delay } from '~/utils/runtime';
import { isMobile } from '~/utils/isMobile';

type TProps = {
  history: History;
}

const DELAY_SCROLL_TO_TOP = 100;

function ScrollToTop(props: TProps) {
  const { history } = props;

  const scrollToTop = (smooth?: boolean) => window.scrollTo({ top: 0, left: 0, behavior: smooth ? 'smooth' : undefined });

  const handleMobileScrollToTop = () => history.listen(() => {
    scrollToTop(true);

    delay(DELAY_SCROLL_TO_TOP).then(() => scrollToTop(true));
  });

  const handleDesktopScrollToTop = () => history.listen(() => scrollToTop());

  useEffect(() => {
    const unlisten = isMobile()
      ? handleMobileScrollToTop()
      : handleDesktopScrollToTop();

    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
