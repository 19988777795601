import queryString from 'query-string';

import config from '~/config';
import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import { Post } from './models';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/daily_list_posts`;
const CACHE_KEY_PREFIX = 'daily_list_posts';

export const dailyListPostsFetcher = async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<Post[]>> => {
  let path = PATH();
  const fillParams = {
    ...params,
  };
  const paramsStr = queryString.stringify(fillParams, {
    arrayFormat: 'bracket',
  });

  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path, opts);
  return res.json();
};

const useDailyListPosts = (): ResourceResult<Post[]> =>
  useResource({}, {}, dailyListPostsFetcher, CACHE_KEY_PREFIX);

export default useDailyListPosts;

export const fetchSSRDailyListPosts = async (auth: Auth, opts: RequestInit = {}): Promise<any> => {
  const res = await dailyListPostsFetcher({}, opts);
  auth.setCache(CACHE_KEY_PREFIX, res);
};
