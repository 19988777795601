import React from 'react';

import Title from '~/components/typography/Title';
import c from './HeaderCard.sass';

type TProps = {
  title: string;
  imageSrc: string;
}

export default function HeaderCard(props: TProps) {
  const { title, imageSrc } = props;

  return (
    <div className={c.header_card}>
      <img alt={title} src={imageSrc} className={c.header_card__image} />
      <Title level={4}>{title}</Title>
    </div>
  );
}
