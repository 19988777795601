import { MailingStatus } from '~/resources/useMailing';
import React from 'react';
import Paragraph from '~/components/typography/Paragraph';

import clsx from 'clsx';
import c from './SubscriptionMessage.sass';

type TProps = {
  status: MailingStatus;
  className?: string;
};

export default function SubscriptionMessage(props: TProps) {
  const { status, className } = props;

  switch (status) {
    case MailingStatus.OK: {
      return (
        <div className={clsx(c.message_container, className)}>
          <Paragraph size="small">Есть. Следующее наше письмо будет у вас в почте!</Paragraph>
        </div>
      );
    }
    case MailingStatus.EXISTS: {
      return (
        <div className={clsx(c.message_container, className)}>
          <Paragraph size="small">Кажется, вы уже подписаны.</Paragraph>
          <Paragraph variant="caption">
            Если нет, то напишите нам:
            {' '}
            <a href="mailto:newsletter@reminder.media">
              <u>newsletter@reminder.media</u>
            </a>
          </Paragraph>
        </div>
      );
    }
    case MailingStatus.ERROR: {
      return (
        <div className={clsx(c.message_container, className)}>
          <Paragraph size="small">Что-то пошло не так.</Paragraph>
          <Paragraph variant="caption">
            Напишите нам, если не удалось подписаться:
            {' '}
            <a href="mailto:newsletter@reminder.media">
              <u>newsletter@reminder.media</u>
            </a>
          </Paragraph>
        </div>
      );
    }
    default: {
      return null;
    }
  }
}
