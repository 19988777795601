import React from 'react';
import Paragraph from '~/components/typography/Paragraph';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import c from './TopicBadge.sass';

type TProps = {
  title: string;
  slug: string;
  isTopic?: boolean;
};

export default function TopicBadge(props: TProps) {
  const { title, slug, isTopic } = props;

  const href = `/${isTopic ? 'topic' : 'rubric'}/${slug}`;

  return (
    <Link to={href}>
      <div className={c.topic_badge}>
        <div className={clsx(c.topic_badge__dot, { [c.topic_badge__dot_is_topic]: isTopic })} />
        <Paragraph variant="caption">{title}</Paragraph>
      </div>
    </Link>
  );
}
