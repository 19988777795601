import config from '~/config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const socAuthSendCode = async (auth: Auth) => {
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const provider = url.searchParams.get('provider');
  const path = `${config('API_HOST')}/social_network/register`;
  const res = await anyFetch(path, {
    method: 'POST',
    body: JSON.stringify({
      client_id: config('CLIENT_ID'),
      client_secret: config('CLIENT_SECRET'),
      grant_type: 'social_network',
      provider,
      code,
    }),
  });
  const data = await res.json();
  if (data && data.access_token) {
    auth.setAuth(data);
    return 'success';
  }
  return 'error';
};

export default socAuthSendCode;
