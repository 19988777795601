import React, { FunctionComponent } from 'react';

const LogoMailruIcon: FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5910_2393)">
      <path
        d="M15.6058 12C15.6058 13.9905 13.9869 15.6097 11.9969 15.6097C10.007 15.6097 8.38807 13.9905 8.38807 12C8.38807 10.0095 10.007 8.39025 11.9969 8.39025C13.9869 8.39025 15.6058 10.0095 15.6058 12ZM11.9969 0C5.38212 0 0 5.3835 0 12C0 18.6165 5.38212 24 11.9969 24C14.4203 24 16.7575 23.2785 18.7549 21.9128L18.7895 21.8888L17.1728 20.0093L17.1459 20.0272C15.608 21.0165 13.8272 21.54 11.9969 21.54C6.73778 21.54 2.45937 17.2605 2.45937 12C2.45937 6.7395 6.73778 2.46 11.9969 2.46C17.2561 2.46 21.5345 6.7395 21.5345 12C21.5345 12.6818 21.4588 13.3717 21.3095 14.0505C21.0081 15.2887 20.1413 15.6682 19.4906 15.618C18.836 15.5647 18.0704 15.0982 18.0651 13.9567V13.026V12C18.0651 8.65275 15.3434 5.93025 11.9969 5.93025C8.6505 5.93025 5.92874 8.65275 5.92874 12C5.92874 15.3472 8.6505 18.0697 11.9969 18.0697C13.6226 18.0697 15.1468 17.4338 16.2963 16.278C16.9644 17.3183 18.0539 17.9708 19.2933 18.0697C19.3998 18.0788 19.5085 18.0833 19.6165 18.0833C20.4885 18.0833 21.3538 17.7915 22.0511 17.2612C22.7702 16.7153 23.307 15.9255 23.6039 14.9767C23.6512 14.8237 23.7382 14.4728 23.7389 14.4705L23.7412 14.4578C23.9158 13.6965 23.9939 12.9375 23.9939 12C23.9939 5.3835 18.6117 0 11.9969 0Z"
        fill="#FF9E00"
      />
    </g>
    <defs>
      <clipPath id="clip0_5910_2393">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoMailruIcon;
