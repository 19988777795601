import React from 'react';

import clsx from 'clsx';
import c from './ItemList.sass';

interface Props {
  items: Array<React.FunctionComponent | React.ReactNode>;
  className?: string;
  noDividersIdx?: number[];
}

const ItemList: React.FunctionComponent<Props> = ({
  items, className, noDividersIdx,
}) => {
  const getClass = () => (className ? ` ${c[className]}` : '');

  return (
    <div className={c.container + getClass()}>
      {items.map((item, index) => (
        <div
          key={index}
          className={clsx({
            [c.no_divider]: noDividersIdx?.includes(index),
          })}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

ItemList.defaultProps = {
  className: '',
};

export default ItemList;
