import React from 'react';

import Title from '~/components/typography/Title';

import { subscriptionFeaturesList } from '~/components/PaidSubscription/data/subscriptionFeaturesList';
import SubscriptionFeatureItem from '~/components/PaidSubscription/ui/SubscriptionFeatureItem';
import { guidesItems } from '~/components/PaidSubscription/data/guidesItems';
import { videosItems } from '~/components/PaidSubscription/data/videosItems';
import Carousel from '~/components/carousel/Carousel';
import PostCarouselCard from '~/components/PaidSubscription/ui/PostCarouselCard';
import clsx from 'clsx';
import c from './SubscriptionFeatures.sass';

const FIRST_SECTION_LENGTH = 2;

export default function SubscriptionFeatures() {
  const firstSectionFeatures = subscriptionFeaturesList.slice(0, FIRST_SECTION_LENGTH);

  return (
    <div className={c.subscription_features}>
      <Title level={2} className={c.features_title}>Что входит в подписку</Title>
      {firstSectionFeatures.map((feature, idx) => (
        <SubscriptionFeatureItem key={feature.title} feature={feature} withSeparator={!idx} />
      ))}
      <Carousel className={c.slider}>
        {guidesItems.map((guide) => <PostCarouselCard key={guide.title} {...guide} />)}
      </Carousel>
      <SubscriptionFeatureItem
        feature={subscriptionFeaturesList[FIRST_SECTION_LENGTH]}
        className={c.feature_item_single}
      />
      <Carousel className={c.slider}>
        {videosItems.map((guide) => <PostCarouselCard key={guide.title} {...guide} dark />)}
      </Carousel>
      <SubscriptionFeatureItem
        feature={subscriptionFeaturesList[FIRST_SECTION_LENGTH + 1]}
        className={clsx(c.feature_item_single, c.feature_item__last)}
      />
      <div className={c.separator} />
    </div>
  );
}
