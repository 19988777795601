import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { Page, Paginator } from '~/components';
import config from '~/config';
import TopicPostList from '~/components/post/TopicPostList';
import Banner from '~/components/Banner';
import JsonLd from './jsonLd';
import Helmeter from './Helmeter';

type Params = {
  topic: string;
};

const TopicScreen: FunctionComponent = () => {
  const { topic } = useParams<Params>();

  const canonicalLink = `${config('APP_HOST')}/${`topic/${topic}`}`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <JsonLd name={topic} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <TopicPostList slug={topic} page={page} onMeta={handleMeta} onLoading={handleLoading} />
          )}
        </Paginator>
      </Page>
    </>
  );
};

export default TopicScreen;
