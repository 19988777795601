import React from 'react';
import Paragraph from '~/components/typography/Paragraph';

import clsx from 'clsx';
import { usePageContext } from '~/components';

import c from './ContentItem.sass';

type TProps = {
  text: string;
  isActive: boolean;
  idx: number;
  close: () => void;
};

export default function ContentItem(props: TProps) {
  const {
    text, isActive, idx, close,
  } = props;

  const { guideContents } = usePageContext();

  const handleClick = () => {
    if (!guideContents) return;

    close();

    const [_, setGuideContents] = guideContents;

    setGuideContents((prev) => {
      if (!prev) return prev;

      return { ...prev, shouldScrollToIdx: idx };
    });
  };

  return (
    <div className={c.guide_content_item} onClick={handleClick}>
      <Paragraph
        size="small"
        className={c.guide_content_item__text}
      >
        {text}
      </Paragraph>
      <div className={clsx(c.guide_content_item__point, {
        [c.guide_content_item__point_is_active]: isActive,
      })}
      />
    </div>
  );
}
