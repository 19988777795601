import React, { FunctionComponent } from 'react';

const ExpertsBenefitIcon: FunctionComponent = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/Experts">
      <ellipse id="Ellipse 11" cx="13" cy="13.5" rx="6" ry="8.5" fill="#25D3FF" />
      <path id="Vector 16" d="M14.0004 18.5001C8.00053 21.3002 7.83378 31.0001 8.50044 35.5001H33.0005C29.1671 28.6667 22.5005 14.5331 14.0004 18.5001Z" fill="#F85B2E" />
      <path id="Vector 17 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M30.7122 24.6168C28.6748 27.6886 25.5296 30.6231 20.9117 31.9424C11.6164 34.5982 5.24965 28.604 3.21973 25.2823L5.77957 23.718C7.41632 26.3963 12.5829 31.2021 20.0876 29.0579C23.8697 27.9773 26.4745 25.5783 28.2121 22.9586C29.9637 20.3177 30.7896 17.5133 31.0122 15.8062L33.987 16.1942C33.7097 18.3204 32.7356 21.5659 30.7122 24.6168Z" fill="#25D3FF" />
    </g>
  </svg>
);

export default ExpertsBenefitIcon;
