import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { makeSubscriptionPayment } from '~/resources/useSubscriptionList';
import { initPaymentForm } from '~/utils/payment';
import { SubscriptionPlan } from '~/resources/models';
import { SubscriptionStatus } from '~/utils/purshase-event';
import CloseIcon from '~/components/Subscription/ModalSubscription/CloseIcon';
import disableScroll from '~/utils/disableScroll';
import Loader from '../Loader';
import c from './PaidSubscriptionForm.sass';

export interface SubscriptionPayment {
  plan: SubscriptionPlan;
  promocode: string;
  email?: string;
  onError: (status: SubscriptionStatus) => void;
  onSuccess: (orderId: number) => void;
  onClose: () => void;
  postSlug?: string;
}

export const SubscriptionPayment: FunctionComponent<SubscriptionPayment> = ({
  plan, promocode, email, onError, onClose, onSuccess, postSlug,
}) => {
  const [ready, setReady] = useState(false);

  disableScroll(true);
  const onHandleClose = () => {
    disableScroll(false);
    onClose();
  };
  const onHandleError = (status: SubscriptionStatus) => {
    disableScroll(false);
    onError(status);
  };
  const onHandleSuccess = (id: number) => {
    disableScroll(false);
    onSuccess(id);
  };

  const makePayment = async () => {
    const token = localStorage.getItem('access_token');
    if (!token || !plan) {
      onHandleError(SubscriptionStatus.UNAUTHORIZED);
    }
    const response = await makeSubscriptionPayment(plan.id, promocode, email, postSlug);
    if (!response.error) {
      setReady(true);
      initPaymentForm({
        confirmationType: response.confirmation.type,
        confirmationToken: response.confirmation.confirmation_token,
        confirmationUrl: response.confirmation.confirmation_url,
        elementId: 'payment-form',
        onSuccess: () => onHandleSuccess(response.data.id),
        onError: () => onHandleError(SubscriptionStatus.PAYMENT_FORM_ERROR),
      });
    } else {
      onHandleError(
        response.status as SubscriptionStatus
        || SubscriptionStatus.SOMETHING_WENT_WRONG,
      );
    }
  };

  useEffect(() => {
    makePayment();
  }, []);

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      <div className={`${c.payment_form_wrapper}`}>
        <button className={c.modal__close} type="button" onClick={onHandleClose}>
          <CloseIcon />
        </button>
        <div className={c.payment_form}>
          <div className={c.payment_form_container} id="payment-form">
            {!ready && <Loader />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

SubscriptionPayment.defaultProps = {
  email: undefined,
  postSlug: undefined,
};
