import React, { FunctionComponent } from 'react';

const BackIcon: FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      fill="#F2F2F2"
    />
    <g id="Components">
      <rect
        width="1740"
        height="1531"
        transform="translate(-1000 -562)"
        fill="white"
      />
      <g id="interface-icons">
        <rect
          x="-66.5"
          y="-195.5"
          width="111"
          height="283"
          rx="4.5"
          stroke="#9747FF"
          strokeDasharray="10 5"
        />
        <g id="name=back">
          <rect
            width="24"
            height="24"
            fill="white"
          />
          <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.41436 11L14.1927 4.22168L12.7785 2.80746L3.58609 11.9998L3.58617 11.9999L3.58594 12.0002L12.7782 21.1924L14.1924 19.7782L7.41421 13H22.0002V11H7.41436Z"
            // fill="#F85B2E"
            // fill="#A5A5A5"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default BackIcon;
